<template>

  <!-- Profit And Cash Flow Table -->
  <div class="position-relative bg-white">

    <div>

      <!-- Title & Period Switcher -->
      <div class="d-flex flex-column flex-md-row align-items-center align-items-md-start justify-content-between title-and-switcher-container">

        <!-- Title -->
        <h3 class="m-0 mb-3 mb-md-0 text-center text-md-left">
          {{ $t('charts.profitAndLossStatement') }}
        </h3>
        <!-- /Title -->

        <!-- Period Switcher -->
        <div
          v-if="!tableAdjustment.journalPage"
          class="period-switcher"
        >
          <table-period-switcher
            @change="period = $event"
          />
        </div>
        <!-- /Period Switcher -->

      </div>
      <!-- /Title & Period Switcher -->

      <!-- Table -->
      <div id="income-expenses-table">
        <el-table
          ref="table"
          :data="tableData"
          row-key="id"
          :border="false"
          :stripe="false"
          size="small"
          default-collapse-all
        >

          <!-- Fixed Column -->
          <el-table-column
            class-name="main-cells-1"
            label-class-name=""
            :fixed="!$breakpoint.xs"
            prop="name"
            :label="$t('pages.businessGuide.profitAndCashFlow.profitAndLoss')"
            :width="tableFirstColumnWidth"
          />
          <!-- /Fixed Column -->

          <!-- Period Columns -->
          <el-table-column
            v-for="column in columns"
            :key="column.id"
            class-name="main-cells"
            label-class-name="table-labels"
            align="right"
            :label="column.label"
            :min-width="tableAdjustment.columnWidth"
          >
            <template slot-scope="scope">
              <span class="currency">{{ getCellSymbol(scope, column) }}</span>
              <span>{{ scope.row.values[column.name] ? getRoundedFormattedAmount(scope.row.values[column.name]) : '' }}</span>
            </template>
          </el-table-column>
          <!-- /Period Columns -->

        </el-table>
      </div>
      <!-- /Table -->

    </div>

  </div>
  <!-- /Profit And Cash Flow Table -->

</template>

<script>
import MixinCurrencies from '@/mixins/currencies'
import MixinTable from '@/mixins/table'
import TablePeriodSwitcher from '@/components/Charts/Table/TablePeriodSwitcher'
import { mapMutations } from 'vuex'

export default {
  name: 'ProfitAndLossTable',

  components: {
    TablePeriodSwitcher
  },

  mixins: [
    MixinCurrencies,
    MixinTable
  ],

  props: {
    tableAdjustment: {
      type: Object,
      default: () => ({
        journalPage: false,
        period: 'monthly',
        tableFirstColumnWidth: 250,
        columnWidth: 114
      })
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      period: this.tableAdjustment.period
    }
  },

  computed: {
    tableData () {
      let tableData = []

      if (this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation &&
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.incomeExpensesTable &&
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.incomeExpensesTable[this.period]) {
        tableData = this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.incomeExpensesTable[this.period]
      }

      return tableData
    },

    isOnFreeTrial () {
      return !(this.$store.state.user.freeTrial === null)
    },

    columns () {
      let columns = []

      if (
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation &&
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.columns[this.period]
      ) {
        columns = this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.columns[this.period]
      }

      return columns
    }
  },

  mounted () {
    this.switchTablePeriod(this.tableAdjustment.period)
  },

  methods: {
    ...mapMutations('ui', [
      'setDialogUpgradeState'
    ]),

    switchTablePeriod (period) {
      this.$set(this, 'period', period)
    },

    showUpgradeDialog () {
      this.setDialogUpgradeState(true)
    },

    getCellSymbol (scope, column) {
      return (scope.row.values[column.name] && typeof scope.row.percentage === 'undefined') ? this.currencySymbol : (scope.row.percentage && typeof scope.row.values[column.name] !== 'undefined' && scope.row.values[column.name] !== 0 ? '%' : '')
    }
  }
}
</script>
<style scoped>
.period-switcher {
  max-width: 150px;
  min-width: 150px;
}

.title-and-switcher-container {
  padding: 0 30px;
}
</style>
