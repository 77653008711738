<template>
  <div
    class="form-switch-wrapper d-flex align-items-center text-center"
    :class="classes"
  >
    <template v-for="(option, index) in options">
      <input
        :id="`${option.value}-${randomId}`"
        :key="index"
        type="radio"
        class="switch-input"
        :class="{'active': option.value === value}"
        :value="option.value"
        :checked="option.value === value"
        :disabled="disabled"
        @click="onClick(option)"
      >
      <label
        :key="`${option.value}-${randomId}`"
        :style="{ width: `${labelWidth}%` }"
        :for="`${option.value}-${randomId}`"
        class="switch-label h-100 d-flex flex-column justify-content-center"
        :class="labelClasses"
      >
        {{ option.title }}
      </label>
    </template>
    <span
      class="form-switch-selection"
      :class="[classes, selectionClass]"
      :style="{ width: `${labelWidth}%`, left: `${selectionPosition}%` }"
    />
  </div>
</template>

<script>
/** Extra Small size **/
const XS = 'xs'

/** Small size **/
const SM = 'sm'

/** Medium size **/
const MD = 'md'

/** Colors **/
const COLORS = ['white', 'blue', 'yellow']

export default {
  name: 'FormSwitcher',

  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: [String, Boolean, Number],
      required: true
    },
    size: {
      type: String,
      validator: value => {
        return [XS, SM, MD].includes(value)
      },
      default: MD
    },
    variant: {
      type: String,
      validator: value => {
        return COLORS.includes(value)
      },
      default: 'white'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      randomId: null,
      selectionPosition: 0
    }
  },

  computed: {
    content: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },

    optionsLength () {
      return this.options.length
    },

    labelWidth () {
      return 100 / this.optionsLength
    },

    classes () {
      return [
        this.sizeClass,
        this.colorClass,
        this.disabledClass
      ]
    },

    labelClasses () {
      return [
        this.disabledClass,
        this.colorClass,
        {
          'cursor-pointer': !this.disabled,
          'cursor-not-allowed': this.disabled
        }
      ]
    },

    selectionClass () {
      return `form-selection-${this.size}-size`
    },

    sizeClass () {
      return `form-switcher-${this.size}-size`
    },

    colorClass () {
      return `form-switcher-${this.variant}`
    },

    disabledClass () {
      return this.disabled ? 'form-switcher-disabled' : null
    }
  },

  watch: {
    value: {
      handler () {
        this.setSelectionPosition()
      },
      immediate: true
    }
  },

  mounted () {
    this.randomId = this.generateRandomId()
  },

  methods: {
    onClick (option) {
      this.content = option.value
      if (option.callback) {
        option.callback()
      }
      this.$emit('change', option)
    },

    setSelectionPosition () {
      const found = this.options.find(option => option.value === this.value)
      const index = this.options.indexOf(found)
      this.selectionPosition = 100 / this.optionsLength * index
    },

    generateRandomId () {
      return Math.random()
        .toString(36)
        .substring(7)
    }
  }
}
</script>
<style scoped lang="scss">
.form-switch-wrapper {
  position: relative;
  height: 38px;
  width: 100%;
  background: rgba(230, 230, 230, 0.25);
  border-radius: 20px;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
}

.form-switch-selection {
  position: absolute;
  border-radius: 20px;

  box-shadow: inset 0 1px rgba(255, 255, 255, 0.5), 0 0 2px rgba(0, 0, 0, 0.2);
  transition: left 0.25s ease-out;

  &.form-switcher-disabled {
    background-color: unset;
  }

  &.form-switcher-white {
    background-color: $color-white;
  }

  &.form-switcher-blue {
    background-color: $color-primary;
  }

  &.form-switcher-yellow {
    background-color: $color-yellow;
  }
}

.switch-label {
  z-index: 1;
  font-family: outfit-semi-bold;
  color: $color-text-light-gray;
  user-select: none;
}

.switch-input.active + .switch-label {
  &.form-switcher-white {
    color: #0b42b0;
  }

  &.form-switcher-blue {
    color: $color-white;
  }

  &.form-switcher-disabled {
    color: $color-text-light-gray;
  }
}

input {
  display: none;
}

.form-switcher-xs-size {
  height: 22px;
  font-size: 12px;
}

.form-switcher-sm-size {
  height: 32px;
  font-size: 14px;
}

.form-switcher-md-size {
  height: 38px;
}

.form-selection-xs-size {
  height: 20px;
}

.form-selection-sm-size {
  height: 30px;
}

.form-selection-md-size {
  height: 36px;
}
</style>
