import { render, staticRenderFns } from "./MarketingBudgetTable.vue?vue&type=template&id=3a318452&scoped=true"
import script from "./MarketingBudgetTable.vue?vue&type=script&lang=js"
export * from "./MarketingBudgetTable.vue?vue&type=script&lang=js"
import style0 from "./MarketingBudgetTable.vue?vue&type=style&index=0&id=3a318452&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a318452",
  null
  
)

export default component.exports