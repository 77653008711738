<template>
  <!-- Dialog Business Plan Quick Tour -->
  <ib-dialog
    :visible="visible"
    :closable="false"
    empty-header
    top="8vh"
    width="850px"
    @close="skip"
  >
    <!-- Quick Tour -->
    <business-plan-quick-tour video-height="250" />
    <!-- /Quick Tour -->

    <!-- Dialog Footer -->
    <template #footer>
      <ib-divider block class="mb-4" />
      <ib-dialog-footer
        button-size="md"
        :submit-btn-text="$t('upgradeToContinue')"
        :cancel-btn-text="$t('back')"
        :visible-submit-btn="subscriptionIsDreamer || subscriptionIsFreeTrial"
        @submit="$router.push({ name: 'payment' })"
        @cancel="skip"
      />
    </template>
    <!-- /Dialog Footer -->
  </ib-dialog>
  <!-- /Dialog Business Plan Quick Tour -->
</template>

<script>
import BusinessPlanQuickTour from '@/views/Home/Components/QuickTours/BusinessPlanQuickTour'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'

export default {
  name: 'DialogBusinessPlanQuickTour',

  components: {
    BusinessPlanQuickTour
  },

  mixins: [MixinUserSubscription],

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    skip () {
      this.$emit('skip')
      if (this.subscriptionIsDreamer || this.subscriptionIsFreeTrial) {
        this.$router.go(-1)
      }
    }
  }
}
</script>
