import { getImageBySize } from '@/helpers/imageSizeHelper'

export default {
  // computed: {
  //   isMoreDetailsEmpty () {
  //     let parser = new DOMParser()
  //     let parsedHtml = parser.parseFromString(this.$store.state.idea.storyMode.common.moreDetails.ideaMoreDetails, 'text/html')
  //     let pTags = parsedHtml.getElementsByTagName('p')
  //     let vocab = []
  //
  //     pTags.forEach(item => {
  //       if (item.textContent) {
  //         vocab.push(item.textContent)
  //       } else if (item.innerHTML.includes('<img')) {
  //         vocab.push(item.innerHTML)
  //       }
  //     })
  //
  //     return vocab.length === 0
  //   }
  // },

  methods: {
    isOption (idea, option) {
      return !(idea.options || []).includes(option)
    },

    toggleOption (idea, option) {
      this.$store.commit('idea/toggleOption', {
        id: idea.id,
        option: option
      })
    },

    toggleMore () {
      this.moreVisible = !this.moreVisible
    },

    // enableTimeline () {
    //   this.timelineSection = !this.timelineSection
    // },
    //
    // enableBreakdown () {
    //   this.breakdownSection = !this.breakdownSection
    // },
    //
    // enableTable () {
    //   this.tableSection = !this.tableSection
    // },

    cardImage (img) {
      if (img) {
        return 'background-image: url(' + img + ')'
      } else {
        return ''
      }
    },

    cardImageBySize (image, size) {
      return image ? 'background-image: url(' + getImageBySize(image.sizes, size) + ')' : ''
    }
  }
}
