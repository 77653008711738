<template>

  <!-- Startup Cost Breakdown -->
  <el-row :gutter="20">

    <!-- Left Side -->
    <el-col :span="18" class="position-relative">
      <chart-header>
        {{ $t('charts.distributionOfStartupCosts') }}
        <template #right>
          <!-- Breakdown Switcher -->
          <breakdown-total-switcher size="xs" @change="showTotals = $event" />
          <!-- /Breakdown Switcher -->
        </template>
      </chart-header>

      <!-- Chart -->
      <distribution-of-startup-costs-chart
        :chart-adjustment="chartAdjustment"
        :show-totals="showTotals"
        chart-animations
        hide-title
      />
      <!-- /Chart -->

    </el-col>
    <!-- /Left Side -->

    <!-- Right Side -->
    <el-col :span="6">
      <div v-if="$store.state.idea.storyMode.setUp.startupCosts.breakdown" class="breakdown">

        <template v-if="$store.state.idea.storyMode.setUp.startupCosts.breakdown.totalCosts">
          <!-- Total Costs -->
          <ib-tooltip v-if="Math.abs($store.state.idea.storyMode.setUp.startupCosts.breakdown.totalCosts) >= 1.0e+7" :content="'Total Startup Costs: ' + chartTooltipDisplay($store.state.idea.storyMode.setUp.startupCosts.breakdown.totalCosts)" placement="right" :open-delay="0">
            <p v-if="$store.state.idea.storyMode.setUp.startupCosts.breakdown.totalCosts" class="total-costs">
              {{ chartValueFormatter($store.state.idea.storyMode.setUp.startupCosts.breakdown.totalCosts) }}
            </p>
          </ib-tooltip>

          <p v-else class="total-costs">
            {{ chartValueFormatter($store.state.idea.storyMode.setUp.startupCosts.breakdown.totalCosts) }}
          </p>
        <!-- /Total Costs -->
        </template>

        <!-- Total Costs Title -->
        <p v-if="$store.state.idea.storyMode.setUp.startupCosts.breakdown.totalCosts" class="text-small">
          {{ $t('charts.totalStartupCosts') }}
        </p>
        <!-- /Total Costs Title -->

        <!-- Details -->
        <div v-if="$store.state.idea.storyMode.setUp.startupCosts.breakdown.topCosts" class="details">

          <!-- Top 3 Costs -->
          <p class="text-small">
            {{ $t('charts.topThreeCosts') }}
          </p>
          <!-- /Top 3 Costs -->

          <!-- Costs -->
          <div v-for="(cost, index) in $store.state.idea.storyMode.setUp.startupCosts.breakdown.topCosts"
               :key="cost.id"
               class="flex"
          >
            <div class="number">
              {{ index + 1 }}
            </div>
            <div class="name-and-amount">
              <ib-tooltip v-if="Math.abs(cost.amount) >= 1.0e+7" :content="cost.name + ': ' + chartTooltipDisplay(cost.amount)" placement="right" :open-delay="0">
                <p class="bold">
                  {{ chartValueFormatter(cost.amount) }}
                </p>
              </ib-tooltip>

              <p v-else class="bold">
                {{ chartValueFormatter(cost.amount) }}
              </p>

              <p class="text-small">
                {{ cost.name }}
              </p>
            </div>
          </div>
          <!-- /Costs -->

        </div>
        <!-- /Details -->

      </div>
    </el-col>
    <!-- /Right Side -->

  </el-row>
  <!-- /Startup Cost Breakdown -->

</template>

<script>
import BreakdownTotalSwitcher from '@/components/Charts/Breakdown/BreakdownTotalSwitcher'
import ChartHeader from '@/views/Home/StoryMode/Components/BusinessPlan/ChartHeader'
import DistributionOfStartupCostsChart from '@/views/Home/StoryMode/SetUp/StartupCosts/Charts/DistributionOfStartupCostsChart'
import MixinCalculations from '@/mixins/calculations'
import MixinCharts from '@/mixins/charts'
import MixinCurrencies from '@/mixins/currencies'

export default {
  name: 'StartupCostsBreakdown',

  components: {
    BreakdownTotalSwitcher,
    ChartHeader,
    DistributionOfStartupCostsChart
  },

  mixins: [
    MixinCalculations,
    MixinCharts,
    MixinCurrencies
  ],

  props: {
    chartAdjustment: {
      type: Object,
      default: () => ({
        chartHeight: 370,
        headingTitleFontSize: '22px',
        xLabelTitleFontSize: '12px',
        yLabelTitleFontSize: '14px',
        yaxisLabelsMinWidth: {
          breakDown: 250
        }
      })
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      forceUpdate: true,
      showTotals: false
    }
  }
}
</script>
