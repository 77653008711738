<template>

  <!-- Right Side -->
  <el-col :span="6">
    <div class="years-breakdown">

      <!-- Total -->
      <ib-tooltip v-if="Math.abs(Number(total)) >= 1.0e+7" :content="title + ': ' + chartTooltipDisplay(Number(total))" placement="right" :open-delay="0">
        <p class="total-costs">
          {{ chartValueFormatter(Number(total)) }}
        </p>
      </ib-tooltip>

      <p v-else class="total-costs">
        {{ chartValueFormatter(Number(total)) }}
      </p>
      <!-- /Total -->

      <!-- Total Costs Title -->
      <p class="text-small">
        {{ title }}
      </p>
      <!-- /Total Costs Title -->

      <!-- Details -->
      <div class="details">

        <!-- Years -->
        <div v-for="(value, index) in byYear" :key="index" class="flex">
          <div class="number">
            {{ 'Y' + (index + 1) }}
          </div>
          <div class="name-and-amount">
            <ib-tooltip v-if="Math.abs(Number(value)) >= 1.0e+7" :content="getTotalExpensesEndOfYearDateString(index) + ': ' + chartTooltipDisplay(Number(value))" placement="right" :open-delay="0">
              <p class="bold">
                {{ chartValueFormatter(Number(value)) }}
              </p>
            </ib-tooltip>

            <p v-else class="bold">
              {{ chartValueFormatter(Number(value)) }}
            </p>

            <p class="text-small">
              {{ getTotalExpensesEndOfYearDateString(index) }}
            </p>
          </div>
        </div>
        <!-- /Years -->

      </div>
      <!-- /Details -->

    </div>
  </el-col>
  <!-- /Right Side -->

</template>

<script>
import MixinCurrencies from '@/mixins/currencies'

export default {
  name: 'TimelineRightSide',

  mixins: [
    MixinCurrencies
  ],

  props: {
    title: {
      type: String,
      default: ''
    },
    total: {
      type: Number,
      default: 0
    },
    byYear: {
      type: Array,
      default: () => ([])
    }
  },

  data () {
    return {
      dateStringPeriodEndMonth: this.getDateStringPeriodEndMonth(),
      dateStringPlanningStartYear: this.getDateStringPlanningStartYear()
    }
  },

  methods: {
    getDateStringPeriodEndMonth () {
      return this.$moment(this.getIdea().ideaSettings.startingFrom).subtract(1, 'M').format('MMM')
    },

    getDateStringPlanningStartYear () {
      return this.$moment(this.getIdea().ideaSettings.startingFrom).subtract(1, 'M').format('YYYY')
    },

    getTotalExpensesEndOfYearDateString (year) {
      return `${this.$t('charts.endOf')} ${this.dateStringPeriodEndMonth} ${this.$moment(this.dateStringPlanningStartYear, 'YYYY').add(year + 1, 'Y').format('[\']YY')}`
    }
  }
}
</script>
