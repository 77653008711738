var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-col',{staticClass:"d-flex pa-0 justify-content-end"},[_c('v-select',{staticClass:"switcher-container",attrs:{"outlined":"","dense":"","items":[
        {
          text:_vm.$t('monthly'),
          value: 'monthly',
        },
        {
          text:_vm.$t('quarterly'),
          value: 'quarterly',
        },
        {
          text:_vm.$t('yearly'),
          value: 'yearly',
        }
      ]},on:{"change":function($event){return _vm.$emit('change', _vm.period)}},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }