import i18n from '@/i18n'

const allowedImgTypes = ['image/jpeg', 'image/png']
function cardImage (img) {
  if (img) {
    return 'background-image: url(' + img + ')'
  } else {
    return ''
  }
}

function validateImage (file, images) {
  const isAcceptedFormat = allowedImgTypes.includes(file.type)
  const isImageSizeLowerThen5M = file.size / 1024 / 1024 <= 5

  if (images > 9) {
    alert(i18n.t('maximumNumberOfUploadedImages'))

    return false
  }

  if (!isAcceptedFormat) {
    // this.$message.error('Picture must be JPEG or PNG format!')
    alert(i18n.t('pictureMustBeJpeg'))

    return false
  }
  if (!isImageSizeLowerThen5M) {
    // this.$message.error('Picture size can not exceed 5MB!')
    alert(i18n.t('pictureSizeCanNotExceed'))

    return false
  }

  if (!isAcceptedFormat && !isImageSizeLowerThen5M) {
    alert(i18n.t('youCouldOnlyUploadImages'))

    return false
  }

  return true
}

export {
  allowedImgTypes,
  cardImage,
  validateImage
}
