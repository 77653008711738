<template>
  <el-popover
    v-model="visible"
    :placement="placement"
    :width="290"
    trigger="click"
    popper-class="ib-tooltip-confirm"
    :disabled="disabled"
    @show="$emit('show')"
  >
    <div slot="reference">
      <slot />
    </div>

    <div>
      <p class="title">
        {{ text }}
      </p>

      <ib-divider block class="mb-2" />

      <div class="actions d-flex justify-content-end align-items-center">
        <a class="cancel-btn" @click="cancel">
          {{ $t('cancel') }}
        </a>
        <ib-button
          size="sm"
          font-family="regular"
          :loading="loading"
          submit-button
          @click="onConfirm"
        >
          {{ $t('confirm') }}
        </ib-button>
      </div>
    </div>
  </el-popover>
</template>

<script>
export default {
  name: 'IbTooltipConfirm',

  props: {
    text: {
      type: String,
      default: function () {
        return this.$t('areYouSure')
      }
    },

    placement: {
      type: String,
      default: 'top'
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      visible: false
    }
  },

  methods: {
    cancel () {
      this.visible = false
    },

    onConfirm () {
      if (this.disabled) return
      this.$emit('confirm')
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  font-size: 15px;
  text-align: center;
}

.actions {
  column-gap: 20px;
}

.cancel-btn {
  color: #3e474f;
  text-decoration: none;
  opacity: 0.5;

  &:hover {
    opacity: 1;
    text-decoration: underline;
    transition: all 0.25s;
  }
}
</style>
