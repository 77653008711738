<template>
  <div class="d-flex justify-content-between align-items-center chart-header-wrapper">
    <p class="m-0 mr-2">
      <slot />
    </p>
    <slot name="right" />
  </div>
</template>

<script>
export default {
  name: 'ChartHeader'
}
</script>

<style scoped lang="scss">
.chart-header-wrapper {
  padding: 0 0 0 30px;

  p {
    font-size: 18px;
    font-weight: 400;
  }
}
</style>
