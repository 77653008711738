var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"switch-wrapper"},[_c('form-switcher',{attrs:{"size":_vm.size,"options":[{
      title: _vm.$t('detailed'),
      value: false
    },{
      title: _vm.$t('totals'),
      value: true
    }]},on:{"change":function($event){return _vm.$emit('change', _vm.totals)}},model:{value:(_vm.totals),callback:function ($$v) {_vm.totals=$$v},expression:"totals"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }