<template>

  <!-- Startup Cost Timeline -->
  <div class="switcher-inner-wrapper">
    <el-row :gutter="20">

      <!-- Left Side -->
      <el-col :span="19" class="position-relative">
        <chart-header>
          {{ $t('charts.payrollExpensesOverTime') }}
          <template #right>
            <!-- Timeline Period Switcher -->
            <timeline-period-switcher-business-plan
              size="xs"
              @switch-to-monthly="switchToMonthly"
              @switch-to-quarterly="switchToQuarterly"
              @switch-to-yearly="switchToYearly"
            />
            <!-- /Timeline Period Switcher -->
          </template>
        </chart-header>

        <!-- Chart -->
        <payroll-expenses-over-time-chart
          :chart-adjustment="chartAdjustment"
          :period="period"
          chart-animations
          hide-title
        />
        <!-- /Chart -->

      </el-col>
      <!-- /Left Side -->

      <!-- Right Side -->
      <timeline-right-side v-if="$store.state.idea.storyMode.setUp.staffAndPayrolls.timeline.total &&
                             $store.state.idea.storyMode.setUp.staffAndPayrolls.timeline.byYear"
                           :title="$t('charts.totalPayrollExpenses')"
                           :total="$store.state.idea.storyMode.setUp.staffAndPayrolls.timeline.total"
                           :by-year="$store.state.idea.storyMode.setUp.staffAndPayrolls.timeline.byYear"
      />
      <!-- /Right Side -->

    </el-row>
  </div>
  <!-- /Startup Cost Timeline -->

</template>

<script>
import ChartHeader from '@/views/Home/StoryMode/Components/BusinessPlan/ChartHeader'
import MixinCalculations from '@/mixins/calculations'
import MixinCharts from '@/mixins/charts'
import MixinCurrencies from '@/mixins/currencies'
import MixinStaffAndPayroll from '@/mixins/staffAndPayroll'
import PayrollExpensesOverTimeChart from '@/views/Home/StoryMode/SetUp/StaffAndPayroll/Charts/PayrollExpensesOverTimeChart'
import TimelinePeriodSwitcherBusinessPlan from '@/components/Charts/Timeline/TimelinePeriodSwitcherBusinessPlan.vue'
import TimelineRightSide from '@/components/Charts/Timeline/TimelineRightSide'

export default {
  name: 'StaffAndPayrollTimeline',

  components: {
    TimelinePeriodSwitcherBusinessPlan,
    ChartHeader,
    PayrollExpensesOverTimeChart,
    TimelineRightSide
  },

  mixins: [
    MixinCalculations,
    MixinCharts,
    MixinCurrencies,
    MixinStaffAndPayroll
  ],

  props: {
    chartAdjustment: {
      type: Object,
      default: () => ({
        chartHeight: 370,
        headingTitleFontSize: '22px',
        xLabelTitleFontSize: '12px',
        yLabelTitleFontSize: '14px',
        yaxisLabelsMinWidth: {
          timeLine: 70
        }
      })
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      period: 'monthly'
    }
  }
}
</script>
