import { formatNumber } from '@/helpers/utilityHelpers'
import i18n from '@/i18n'

export default {
  chart: {
    animations: {
      enabled: true,
      speed: 500,
      animateGradually: {
        enabled: false
      },
      dynamicAnimation: {
        enabled: false
      }
    }
  },
  legend: {
    show: false
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return `${formatNumber(val)}%`
    },
    style: {
      fontSize: '16px',
      fontFamily: 'outfit-light,arial'
    },
    dropShadow: {
      enabled: false
    }
  },
  plotOptions: {
    pie: {
      expandOnClick: true,
      dataLabels: {
        minAngleToShowLabel: 0.1
      },
      donut: {
        size: '55%'
      }
    }
  },
  noData: {
    text: i18n.t('pages.businessGuide.ownership.noDataAvailable'),
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: '#292f4d',
      fontSize: '18px',
      fontFamily: 'outfit-light,arial'
    }
  },
  tooltip: {
    enabled: true,
    followCursor: false,
    theme: 'dark',
    style: {
      fontSize: '14px',
      fontFamily: 'outfit-light,arial'
    },
    y: {
      title: {
        formatter (seriesName) {
          return seriesName
        }
      },
      formatter: function (value) {
        return ''
      }
    }
  },
  responsive: [
    {
      breakpoint: 390,
      options: {
        plotOptions: {
          pie: {
            customScale: 0.8
          }
        }
      }
    },
    {
      breakpoint: 768,
      options: {
        plotOptions: {
          pie: {
            customScale: 1
          }
        }
      }
    },
    {
      breakpoint: 992,
      options: {
        plotOptions: {
          pie: {
            customScale: 0.9
          }
        }
      }
    },
    {
      breakpoint: 1030,
      options: {
        plotOptions: {
          pie: {
            customScale: 0.8
          }
        }
      }
    },
    {
      breakpoint: 1106,
      options: {
        plotOptions: {
          pie: {
            customScale: 0.9
          }
        }
      }
    }
  ]
}
