<template>
  <ib-toggle-button
    icon="ib-icon ib-icon-circled-play"
    :title="$t('quickTour')"
    :active="active"
    icon-size="30px"
    @click="$emit('click')"
  />
</template>

<script>
import IbToggleButton from '@/components/_v2/Button/IbToggleButton'

export default {
  name: 'IbQuickTourButton',

  components: { IbToggleButton },

  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>
