<template>
  <el-alert
    class="completed-step-banner-wrapper mb-3"
    title="info alert"
    type="info"
    show-icon
    :closable="false"
  >
    <template #title>
      <p class="title m-0 mb-2">
        {{ $t('pages.businessPlan.completedStepBanner.howToCreateMyBusinessPlan') }}
      </p>

      <p class="description m-0">
        {{ $t('pages.businessPlan.completedStepBanner.markSectionsInBusinessGuideAs') }}
      </p>
    </template>
  </el-alert>
</template>

<script>
export default {
  name: 'CompletedStepBanner'
}
</script>

<style scoped lang="scss">
.completed-step-banner-wrapper {
  ::v-deep {
    padding: 15px;
    background-color: #D9E8FF;

    .el-alert__icon {
      font-size: 30px;
      width: 30px;
      color: #5979BD;
    }
  }

  max-width: 840px;
  margin: 0 auto;

  @media only screen and (min-width: 1440px) {
    max-width: unset;
  }

  .title {
    color: #2440A4;
    font-size: 18px;
    font-family: $outfit-medium;
  }

  .description {
    color: #2440A4;
    font-size: 14px;
  }
}
</style>
