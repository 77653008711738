<template>

  <!-- Financing Table -->
  <div class="switcher-inner-wrapper bg-white">
    <el-row :gutter="20">
      <el-col>

        <!-- Title & Period Switcher -->
        <div class="d-flex flex-column flex-md-row align-items-center align-items-md-start justify-content-between title-and-switcher-container">

          <!-- Title -->
          <h3 class="m-0 mb-3 mb-md-0 text-center text-md-left">
            {{ $t('charts.financingSummary') }}
          </h3>
          <!-- /Title -->

          <!-- Period Switcher -->
          <div v-if="!tableAdjustment.journalPage" class="period-switcher">
            <table-period-switcher @change="period = $event" />
          </div>
        <!-- /Period Switcher -->

        </div>
        <!-- /Title & Period Switcher -->

        <!-- Table -->
        <div id="financing-table">
          <el-table
            ref="table"
            :data="tableData"
            row-key="id"
            :border="false"
            :stripe="false"
            size="small"
            default-collapse-all
          >

            <!-- Fixed Column -->
            <el-table-column
              class-name="main-cells-1"
              label-class-name=""
              :fixed="!$breakpoint.xs"
              prop="name"
              :label="$t('tables.financing.header')"
              :width="tableFirstColumnWidth"
            >
              <template slot-scope="scope">
                <span :class="scope.row.class">{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <!-- /Fixed Column -->

            <!-- Period Columns -->
            <el-table-column
              v-for="column in columns"
              :key="column.id"
              class-name="main-cells"
              label-class-name="table-labels"
              align="right"
              :label="column.label"
              :min-width="tableAdjustment.columnWidth"
            >
              <template slot-scope="scope">
                <span class="currency">{{ scope.row.values[column.name] ? currencySymbol : '' }}</span>
                <span :class="scope.row.class">{{ scope.row.values[column.name] ? getRoundedFormattedAmount(scope.row.values[column.name]) : '' }}</span>
              </template>
            </el-table-column>
          <!-- /Period Columns -->

          </el-table>
        </div>
      <!-- /Table -->

      </el-col>
    </el-row>
  </div>
  <!-- /Financing Table -->

</template>

<script>
import MixinCalculations from '@/mixins/calculations'
import MixinCurrencies from '@/mixins/currencies'
import MixinFinancing from '@/mixins/financing'
import MixinTable from '@/mixins/table'
import TablePeriodSwitcher from '@/components/Charts/Table/TablePeriodSwitcher'

export default {
  name: 'FinancingTable',

  components: {
    TablePeriodSwitcher
  },

  mixins: [
    MixinCalculations,
    MixinCurrencies,
    MixinFinancing,
    MixinTable
  ],

  props: {
    tableAdjustment: {
      type: Object,
      default: () => ({
        journalPage: false,
        period: 'monthly',
        tableFirstColumnWidth: 250,
        columnWidth: 118
      })
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      period: this.tableAdjustment.period
    }
  },

  computed: {
    tableData () {
      let data = []

      if (
        this.$store.state.idea.storyMode.setUp.financing.table &&
        this.$store.state.idea.storyMode.setUp.financing.table[this.period]
      ) {
        data = this.$store.state.idea.storyMode.setUp.financing.table[this.period]
      }

      return data
    },

    columns () {
      let columns = []

      if (
        this.$store.state.idea.storyMode.setUp.financing.table &&
        this.$store.state.idea.storyMode.setUp.financing.table.columns[this.period]
      ) {
        columns = this.$store.state.idea.storyMode.setUp.financing.table.columns[this.period]
      }

      return columns
    }
  }
}
</script>
<style scoped>
.period-switcher {
  max-width: 150px;
  min-width: 150px;
}

.title-and-switcher-container {
  padding: 0 30px;
}
</style>
