<template>
  <ib-tooltip popper-class="plan-popper">
    <!-- Tooltip Content -->
    <div
      slot="content"
      class="tooltip-content"
    >
      <p>{{ $t('pages.businessPlan.showHidePageItems') }}</p>
    </div>
    <!-- /Tooltip Content -->
    <a
      class="more"
      :class="{'horizontal': visible}"
      @click="$emit('click')"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 341.333 341.333" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M170.667 85.333c23.573 0 42.667-19.093 42.667-42.667C213.333 19.093 194.24 0 170.667 0S128 19.093 128 42.667c0 23.573 19.093 42.666 42.667 42.666zM170.667 128C147.093 128 128 147.093 128 170.667s19.093 42.667 42.667 42.667 42.667-19.093 42.667-42.667S194.24 128 170.667 128zM170.667 256C147.093 256 128 275.093 128 298.667c0 23.573 19.093 42.667 42.667 42.667s42.667-19.093 42.667-42.667C213.333 275.093 194.24 256 170.667 256z"/></svg>
    </a>
  </ib-tooltip>
</template>

<script>
export default {
  name: 'TogglePageItemsButton',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  }
}
</script>
