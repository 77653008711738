<template>
  <div
    v-if="$store.state.idea.storyMode.common.completedSteps.marketCompleted === true && isMarketVisible"
    class="journal-content dynamic-height"
  >
    <div class="content-menus">
      <div v-if="moreVisible === true" class="view-type">
        <a
          v-if="coverageElement.title !== ''"
          class="orange"
          :class="{'orange-off': !isOption(marketData, 'coverageCard')}"
          @click="enableCoverage"
        >
          {{ $t('pages.businessPlan.sections.market.coverage') }}
        </a>
        <a
          v-if="growthRateElement && growthRateElement.title !== ''"
          class="orange"
          :class="{'orange-off': !isOption(marketData, 'growthCard')}"
          @click="enableGrowth"
        >
          {{ $t('pages.businessPlan.sections.market.growth') }}
        </a>
        <p v-if="coverageElement.title === '' && growthRateElement.title === ''">
          {{ $t('pages.businessPlan.sections.youDontHaveAnyDataOnThisPage') }}
        </p>
      </div>
      <div v-if="canEdit" class="mini-actions">
        <toggle-page-items-button
          :visible="moreVisible"
          @click="toggleMore"
        />
        <toggle-view-button
          :disabled="!cardExist"
          :horizontal="!isOption(marketData, 'gridListView')"
          @click="toggleRotate"
        />
        <toggle-notes-button
          :disabled="!moreDetails"
          :visible="isOption(marketData, 'moreDetailsDisplay')"
          @click="enableMoreDetails"
        />
        <edit-section-link route-name="business-guide-research-market" />
      </div>
    </div>
    <div class="sub chapter">
      <header>
        <!-- Title section -->
        <editable-title
          v-model="marketData.title"
          tag="h1"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Title section -->
        <hr>
        <!-- Description section -->
        <editable-description
          v-model="marketData.subTitle"
          :empty-text="$t('addMoreDetails.addNoteText')"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Description section -->
      </header>

      <template v-if="$store.state.idea.storyMode.research.market.coverageElementSelected">
        <div v-if="isOption(marketData, 'coverageCard')" class="coverage-wrapper">
          <p class="bold mb-3">
            {{ $t('pages.businessPlan.sections.market.marketCoverage') }}
          </p>

          <div class="coverage-card">
            <!--<div class="absolute">
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#fff" fill-rule="evenodd" d="M1.5 12.78l8.34-8.575 2.14 2.197-8.35 8.56H1.5v-2.183zM12.125 1.853l2.15 2.203-1.23 1.26L10.9 3.119l1.225-1.265zM.75 16.498h3.19c.2 0 .39-.082.53-.226L15.865 4.6a.777.777 0 000-1.086L12.655.225a.74.74 0 00-.53-.225.74.74 0 00-.53.225L.22 11.92a.796.796 0 00-.22.543v3.268c0 .425.335.768.75.768z"/></svg>
            </div>-->
            <img :src="require(`@/assets/img/icons/story-mode/market/${coverageElement.image}`)">

            <h3>{{ coverageElement.title }}</h3>

            <p v-html="coverageElement.text">
              {{ coverageElement.text }}
            </p>
          </div>
        </div>
      </template>

      <template v-if="$store.state.idea.storyMode.research.market.growthRateElementSelected">
        <div v-if="isOption(marketData, 'growthCard')" class="growth-wrapper">
          <p class="bold mb-3">
            {{ $t('pages.businessPlan.sections.market.marketGrowth') }}
          </p>

          <div class="growth-card">
            <!--<div class="absolute">
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#fff" fill-rule="evenodd" d="M1.5 12.78l8.34-8.575 2.14 2.197-8.35 8.56H1.5v-2.183zM12.125 1.853l2.15 2.203-1.23 1.26L10.9 3.119l1.225-1.265zM.75 16.498h3.19c.2 0 .39-.082.53-.226L15.865 4.6a.777.777 0 000-1.086L12.655.225a.74.74 0 00-.53-.225.74.74 0 00-.53.225L.22 11.92a.796.796 0 00-.22.543v3.268c0 .425.335.768.75.768z"/></svg>
            </div>-->

            <img :src="require(`@/assets/img/icons/story-mode/market/${growthRateElement.image}`)" alt="">

            <h3>{{ growthRateElement.title }}</h3>

            <p v-html="growthRateElement.text">
              {{ growthRateElement.text }}
            </p>

          </div>
        </div>
      </template>
      <!-- More Details section -->
      <template v-if="moreDetails && isOption(marketData, 'moreDetailsDisplay')">
        <div class="details">
          <editable-title
            v-model="marketData.moreDetailsTitle"
            class="bold"
            tag="p"
            :disabled="!canEdit"
            @save="onSave"
          />
          <p v-html="moreDetails" />
        </div>
      </template>
      <!-- More Details section -->
    </div>
  </div>
</template>

<script>
import EditSectionLink from '@/views/Home/StoryMode/Components/BusinessPlan/EditSectionLink'
import EditableDescription from '@/components/_v2/EditableDescription'
import EditableTitle from '@/components/_v2/EditableTitle'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import ToggleNotesButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleNotesButton'
import TogglePageItemsButton from '@/views/Home/StoryMode/Components/BusinessPlan/TogglePageItemsButton'
import ToggleViewButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleViewButton'
import journalMethods from '@/mixins/journalMethods'

export default {
  name: 'Market',

  components: {
    EditSectionLink,
    EditableDescription,
    EditableTitle,
    ToggleNotesButton,
    TogglePageItemsButton,
    ToggleViewButton
  },

  mixins: [
    journalMethods,
    MixinIdeaRoles
  ],

  props: {
    allPagesTextData: {
      type: Object,
      default: () => ({})
    },
    shareMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      marketData: {},
      cardExist: false,
      moreVisible: false,
      moreDetails: this.$store.state.idea.storyMode.common.moreDetails.marketMoreDetails,
      coverageElement: this.$store.state.idea.storyMode.research.market.coverageElement,
      growthRateElement: this.$store.state.idea.storyMode.research.market.growthRateElement
    }
  },

  computed: {
    isMarketVisible () {
      const index = this.$store.state.idea.journal.journalSwitchBars.indexOf(
        this.$store.state.idea.journal.journalSwitchBars.find(journalElement => journalElement.sectionName === 'Market')
      )

      return !this.$store.state.idea.journal.journalSwitchBars[index].options.includes('switch')
    },

    isTitleEnabled () {
      return this.marketData.title
    },

    isMoreDetailsTitleEnabled () {
      return this.marketData.moreDetailsTitle
    }
  },

  mounted () {
    this.marketData = Object.assign({}, this.allPagesTextData)
  },

  methods: {
    toggleRotate () {
      if (this.cardExist) {
        this.toggleOption(this.marketData, 'gridListView')
        this.marketData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    enableMoreDetails () {
      if (this.moreDetails) {
        this.toggleOption(this.marketData, 'moreDetailsDisplay')
        this.marketData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    onSave () {
      this.$emit('save', this.marketData)
    },

    enableCoverage () {
      this.toggleOption(this.marketData, 'coverageCard')
      this.marketData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    },

    enableGrowth () {
      this.toggleOption(this.marketData, 'growthCard')
      this.marketData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    }
  }
}
</script>
