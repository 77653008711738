<template>
  <!-- Dialog Share -->
  <ib-dialog
    id="dialog-share"
    :visible="visible"
    top="8vh"
    empty-header
    @close="closeDialog"
  >
    <!-- Title -->
    <template #title>
      <h2 class="m-0">
        {{ title }}
      </h2>
    </template>
    <!-- /Title -->

    <!-- Image -->
    <img src="@/assets/img/illustrations/fogg-message-sent.svg" alt="">
    <!-- /Image -->

    <div class="form-wrapper">
      <el-form ref="form">
        <!-- Share Link -->
        <el-form-item class="label-outside" :label="$t('dialogs.shareBusinessPlan.shareLink')">
          <el-input
            :value="shareLink"
            placeholder=" "
            :readonly="true"
          />
          <a class="copy-link" @click="doCopy">
            {{ $t('dialogs.share.copyLink') }}
          </a>
        </el-form-item>

        <h5 class="text-center mb-0">
          {{ $t('dialogs.share.copyLinkAndShare') }}
        </h5>
        <!-- /Share Link -->
      </el-form>
    </div>
  </ib-dialog>
  <!-- /Dialog Share -->
</template>

<script>
const types = ['ideaPlan', 'financialPlan', 'businessPlan']

const routes = {
  ideaPlan: 'idea-plan-shared',
  financialPlan: 'financial-plan-shared',
  businessPlan: 'business-plan-shared'
}

export default {
  name: 'DialogShare',

  props: {
    visible: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: null
    },

    token: {
      type: String,
      default: null,
      required: true
    },
    type: {
      type: String,
      validator: value => {
        return types.includes(value)
      },
      required: true
    }
  },

  computed: {
    shareLink () {
      if (!this.token) return
      const route = this.$router.resolve({
        name: routes[this.type],
        params: { token: this.token }
      })

      return `${window.location.origin}${route.href}?lang=${localStorage.getItem('lang')}`
    }
  },

  methods: {
    closeDialog () {
      this.$emit('close')
    },

    doCopy: function () {
      this.$copyText(this.shareLink)
      this.$notify({ title: 'Success', message: 'Share Link has been copied', type: 'success' })
    }
  }
}
</script>
