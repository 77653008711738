const UNSUPPORTED_STRINGS = [
  {
    name: 'ProWritingAid',
    value: '<pwa-container',
    message: 'proWritingAidPluginWarning'
  },
  {
    name: 'Ginger',
    value: '<gdiv class="ginger-module',
    message: 'gingerPluginWarning'
  }
]

export default {
  data () {
    return {
      unsupportedPluginNotificationShow: false
    }
  },

  computed: {
    isUnsupportedPluginOn () {
      return UNSUPPORTED_STRINGS.some(obj => this.content.includes(obj.value))
    },

    isUnsupportedPluginOnIdeaPlan () {
      return UNSUPPORTED_STRINGS.some(obj => this.form.text.includes(obj.value))
    }
  },

  methods: {
    showUnsupportedPluginNotification (value) {
      if (!this.unsupportedPluginNotificationShow) {
        this.unsupportedPluginNotificationShow = true
        const searchItem = UNSUPPORTED_STRINGS.find(element => value.includes(element.value))

        this.$notify({
          message: this.$t('unsupportedPluginWarning', { name: searchItem.name }),
          type: 'warning',
          duration: 0,
          onClose: () => {
            this.unsupportedPluginNotificationShow = false
          }
        })
      }
    },

    closeUnsupportedPluginNotification () {
      this.$notify.closeAll()
    }
  },

  beforeDestroy () {
    this.closeUnsupportedPluginNotification()
  }
}
