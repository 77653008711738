<template>
  <ib-dialog
    :visible="dialogVisible"
    top="5vh"
    @close="closeDialog"
    @closed="onClose"
    @opened="generateDocument"
  >
    <div class="dialog-body">
      <div class="illustration-wrapper">
        <img src="@/assets/img/illustrations/export.svg" alt="" class="w-100">
      </div>
    </div>
    <div class="d-flex flex-column align-items-center">
      <template v-if="loading">
        <h1 class="text-center">
          {{ $t('dialogs.export.exportingInProgress') }}
        </h1>
        <i class="el-icon-loading" />
        <p class="text-lead o-50">
          {{ $t('pleaseWait') }}...
        </p>
      </template>

      <h5 v-if="error" class="text-danger">
        {{ $t('somethingWentWrongTryAgainLater') }}
      </h5>

      <template v-if="generateDialogUrl">
        <h1 class="text-center">
          {{ $t('dialogs.export.exportCompleted') }}
        </h1>
        <a :href="generateDialogUrl" target="_blank">
          <ib-button class="text-uppercase">
            {{ $t('openFile') }}
          </ib-button>
        </a>
      </template>
    </div>
  </ib-dialog>
</template>

<script>
export default {
  name: 'DialogGenerate',

  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    sharedIdea: {
      type: Object,
      default: null
    },
    api: {
      type: String,
      default: '/story-mode/overview/generate'
    },
    format: {
      type: String,
      default: 'pdf'
    },
    active: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      loading: false,
      error: false,
      generateDialogUrl: '',
      generateDocumentName: 'overview'
    }
  },

  methods: {
    closeDialog () {
      this.$emit('close')
    },

    onClose () {
      this.generateDialogUrl = ''
      this.error = false
    },

    getIdea () {
      return this.sharedIdea || this.$store.state.idea
    },

    async generateDocument () {
      this.generateDialogUrl = ''
      this.generateDialog = true
      this.loading = true

      this.$gtm.trackEvent({
        event: this.format + 'Export'
      })

      try {
        const response = await this.$http.post(
          this.api,
          {
            ideaId: this.getIdea().id,
            format: this.format,
            active: this.active
          }
        )

        if (response.status === 200) {
          this.generateDialogUrl = response.data.payload.url
          this.loading = false
        }
      } catch (e) {
        this.error = true
        this.loading = false
      }
    }
  }
}
</script>
