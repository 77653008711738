<template>
  <!-- Marketing Budget Breakdown -->
  <el-row :gutter="20">

    <!-- Left Side -->
    <el-col :span="18" class="position-relative">
      <chart-header>
        {{ $t('charts.distributionOfMarketingBudget') }}
        <template #right>
          <!-- Breakdown Switcher -->
          <breakdown-total-switcher size="xs" @change="showTotals = $event" />
          <!-- /Breakdown Switcher -->
        </template>
      </chart-header>

      <!-- Chart -->
      <distribution-of-marketing-budget-chart
        :show-totals="showTotals"
        :chart-adjustment="chartAdjustment"
        chart-animations
        hide-title
      />
      <!-- /Chart -->
    </el-col>
    <!-- /Left Side -->

    <!-- Right Side -->
    <el-col :span="6">
      <div class="breakdown">

        <!-- Total Marketing Budget -->
        <ib-tooltip v-if="Math.abs(Number(totalMarketingBudget)) >= 1.0e+7" :content="'Total Monthly (Average): ' + chartTooltipDisplay(Number(totalMarketingBudget))" placement="right" :open-delay="0">
          <p class="total-costs">
            {{ chartValueFormatter(Number(totalMarketingBudget)) }}
          </p>
        </ib-tooltip>

        <p v-else class="total-costs">
          {{ chartValueFormatter(Number(totalMarketingBudget)) }}
        </p>
        <!-- /Total Marketing Budget -->

        <!-- Total Marketing Budget Title -->
        <p class="text-small">
          {{ $t('totalMonthlyAverage') }}
        </p>
        <!-- /Total Marketing Budget Title -->

        <!-- Details -->
        <div class="details">

          <!-- Top 3 Marketing Budget -->
          <p class="text-small">
            {{ $t('topThreeAverage') }}
          </p>
          <!-- /Top 3 Marketing Budget -->

          <!-- Marketing Budget -->
          <div v-for="(MarketingBudget, index) in topMarketingBudget" :key="MarketingBudget.id" class="flex">
            <div class="number">
              {{ index + 1 }}
            </div>
            <div v-if="MarketingBudget.average" class="name-and-amount">
              <ib-tooltip v-if="Math.abs(Number(MarketingBudget.average)) >= 1.0e+7" :content="MarketingBudget.name + ': ' + chartTooltipDisplay(Number(MarketingBudget.average))" placement="right" :open-delay="0">
                <p class="bold">
                  {{ chartValueFormatter(Number(MarketingBudget.average)) }}
                </p>
              </ib-tooltip>

              <p v-else class="bold">
                {{ chartValueFormatter(Number(MarketingBudget.average)) }}
              </p>

              <p class="text-small">
                {{ MarketingBudget.name }}
              </p>
            </div>
          </div>
          <!-- Marketing Budget -->

        </div>
        <!-- /Details -->

      </div>
    </el-col>
    <!-- /Right Side -->

  </el-row>
  <!-- /Marketing Budget Breakdown -->
</template>

<script>
import BreakdownTotalSwitcher from '@/components/Charts/Breakdown/BreakdownTotalSwitcher'
import ChartHeader from '@/views/Home/StoryMode/Components/BusinessPlan/ChartHeader'
import DistributionOfMarketingBudgetChart
  from '@/views/Home/StoryMode/Forecast/MarketingBudget/Charts/DistributionOfMarketingBudgetChart'
import MixinCharts from '@/mixins/charts'
import MixinCurrencies from '@/mixins/currencies'

export default {
  name: 'MarketingBudgetBreakdown',

  components: {
    ChartHeader,
    DistributionOfMarketingBudgetChart,
    BreakdownTotalSwitcher
  },

  mixins: [
    MixinCharts,
    MixinCurrencies
  ],

  props: {
    chartAdjustment: {
      type: Object,
      default: () => ({
        chartHeight: 370,
        headingTitleFontSize: '22px',
        xLabelTitleFontSize: '12px',
        yLabelTitleFontSize: '14px',
        yaxisLabelsMinWidth: {
          breakDown: 250
        }
      })
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      showTotals: false
    }
  },

  computed: {
    totalMarketingBudget () {
      let result = 0

      if (this.$store.state.idea.storyMode.forecast.marketingBudget.breakdown.totalBudget) {
        result = this.$store.state.idea.storyMode.forecast.marketingBudget.breakdown.totalBudget
      }

      return result
    },

    topMarketingBudget () {
      let result = []

      if (this.$store.state.idea.storyMode.forecast.marketingBudget.breakdown.sorted) {
        result = this.$store.state.idea.storyMode.forecast.marketingBudget.breakdown.sorted
      }

      return result
    }
  }
}
</script>
