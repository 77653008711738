const labelMinWidth = 200
const labelMaxWidth = 200
const labelAlignment = 'right'

const lengthLimit = 24

const formatChartCategories = function (categories) {
  if (!categories.length) return []

  return categories.map(category => {
    const string1 = category.substr(0, lengthLimit)
    const string2 = category.substr(category.lastIndexOf(' ', string1.length))
    const lastWordOfString1 = string1.substring(string1.lastIndexOf(' ') + 1)

    if (category.length <= lengthLimit) {
      return category
    } else {
      return [string1.replace(lastWordOfString1, ''), string2.replace(lastWordOfString1, lastWordOfString1)]
    }
  })
}

export {
  formatChartCategories,
  labelMinWidth,
  labelMaxWidth,
  labelAlignment
}
