export default {
  methods: {
    // TODO - Remove financing.js and all methods. See usage first and refactor.
    calculateLoanOld (amount, annualInterestRate, repaymentPeriod, gracePeriod = null) {
      let monthlyPayment = 0
      let totalPayment = 0
      let totalInterest = 0

      const principal = parseFloat(amount)
      const interestPerPeriod = parseFloat(annualInterestRate) / 100 / 12
      const payments = repaymentPeriod

      // compute the monthly payment figure
      const x = Math.pow(1 + interestPerPeriod, payments)
      const monthly = (principal * x * interestPerPeriod) / (x - 1)

      if (gracePeriod) {
        monthlyPayment = isNaN(monthly) ? amount / repaymentPeriod : parseFloat(monthly.toFixed(2)) * repaymentPeriod / (repaymentPeriod - gracePeriod)
        totalPayment = isNaN(monthly) ? 0 : parseFloat(((interestPerPeriod * principal * gracePeriod) + ((repaymentPeriod - gracePeriod) * monthlyPayment)).toFixed(2))
        totalInterest = totalPayment - amount
      } else {
        monthlyPayment = isNaN(monthly) ? amount / repaymentPeriod : parseFloat(monthly.toFixed(2))
        totalPayment = isNaN(monthly) ? amount : parseFloat((monthly * payments).toFixed(2))
        totalInterest = isNaN(monthly) ? 0 : parseFloat(((monthly * payments) - principal).toFixed(2))
      }

      return {
        interest: interestPerPeriod * amount,
        monthlyPayment: monthlyPayment,
        totalPayment: totalPayment,
        totalInterest: totalInterest
      }
    },

    calculateCreditMonthlyInterest (averageAmount, interestRate) {
      const interest = parseFloat(interestRate) / 100 / 12

      return interest * averageAmount
    },

    calculateCreditTotalInterest (averageAmount, interestRate, howManyTimes, paymentFrequency) {
      const interest = parseFloat(interestRate) / 100 / 12
      const monthlyInterest = interest * averageAmount

      return monthlyInterest * paymentFrequency * howManyTimes
    },

    getPaymentFrequencyInMonths (paymentFrequency) {
      if (paymentFrequency === 4) {
        return 6
      }

      if (paymentFrequency === 5) {
        return 12
      }

      return paymentFrequency
    }
  }
}
