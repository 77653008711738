<template>
  <ib-tooltip popper-class="plan-popper">
    <!-- Tooltip Content -->
    <div
      slot="content"
      class="tooltip-content"
    >
      <p>{{ $t('pages.businessPlan.gridListView') }}</p>
    </div>
    <!-- /Tooltip Content -->
    <a
      class="list-grid"
      :class="{'horizontal': horizontal, 'disabled': disabled}"
      @click="$emit('click')"
    >
      <svg width="20" height="13" viewBox="0 0 20 13" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 0h18a1 1 0 011 1v1a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1zm0 5h18a1 1 0 011 1v1a1 1 0 01-1 1H1a1 1 0 01-1-1V6a1 1 0 011-1zm0 5h18a1 1 0 011 1v1a1 1 0 01-1 1H1a1 1 0 01-1-1v-1a1 1 0 011-1z" fill="none" fill-rule="evenodd" opacity=".205"/></svg>
    </a>
  </ib-tooltip>
</template>

<script>
export default {
  name: 'ToggleViewButton',

  props: {
    disabled: {
      type: Boolean,
      required: true
    },
    horizontal: {
      type: Boolean,
      required: true
    }
  }
}
</script>
