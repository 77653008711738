<template>

  <!-- Financing Timeline -->
  <el-row :gutter="20">

    <!-- Left Side -->
    <el-col :span="18" class="position-relative">
      <chart-header>
        {{ $t('charts.cashFlowFromFinancingActivities') }}
        <template #right>
          <!-- Timeline Period Switcher -->
          <timeline-period-switcher-business-plan size="xs" @change="switchChartPeriod($event)" />
          <!-- /Timeline Period Switcher -->
        </template>
      </chart-header>

      <!-- Chart -->
      <cash-flow-from-financing-activities-chart
        :period="period"
        :chart-adjustment="chartAdjustment"
        chart-animations
        hide-title
      />
      <!-- /Chart -->

    </el-col>
    <!-- /Left Side -->

    <!-- Right Side -->
    <timeline-right-side
      v-if="$store.state.idea.storyMode.setUp.financing.timeline &&
        $store.state.idea.storyMode.setUp.financing.timeline.total &&
        $store.state.idea.storyMode.setUp.financing.timeline.byYear"
      :title="$t('charts.financingBalance')"
      :total="$store.state.idea.storyMode.setUp.financing.timeline.total"
      :by-year="$store.state.idea.storyMode.setUp.financing.timeline.byYear"
    />
    <!-- /Right Side -->

  </el-row>
  <!-- /Financing Timeline -->

</template>

<script>
import CashFlowFromFinancingActivitiesChart from '@/views/Home/StoryMode/SetUp/Financing/Charts/CashFlowFromFinancingActivitiesChart'
import ChartHeader from '@/views/Home/StoryMode/Components/BusinessPlan/ChartHeader'
import MixinCalculations from '@/mixins/calculations'
import MixinCharts from '@/mixins/charts'
import MixinCurrencies from '@/mixins/currencies'
import MixinFinancing from '@/mixins/financing'
import TimelinePeriodSwitcherBusinessPlan from '@/components/Charts/Timeline/TimelinePeriodSwitcherBusinessPlan.vue'
import TimelineRightSide from '@/components/Charts/Timeline/TimelineRightSide'

export default {
  name: 'FinancingTimeline',

  components: {
    TimelinePeriodSwitcherBusinessPlan,
    CashFlowFromFinancingActivitiesChart,
    ChartHeader,
    TimelineRightSide
  },

  mixins: [
    MixinCalculations,
    MixinCharts,
    MixinCurrencies,
    MixinFinancing
  ],

  props: {
    chartAdjustment: {
      type: Object,
      default: () => ({
        chartHeight: 370,
        headingTitleFontSize: '22px',
        xLabelTitleFontSize: '12px',
        yLabelTitleFontSize: '12px',
        yaxisLabelsMinWidth: {
          timeLine: 70
        }
      })
    },
    switchSelector: {
      type: String,
      default: 'financing'
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      period: 'monthly'
    }
  },

  methods: {
    switchChartPeriod (period) {
      this.$set(this, 'period', period)
    }
  }
}
</script>
