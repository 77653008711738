<template>
  <el-row :gutter="30">
    <el-col :xl="12">
      <slot />
    </el-col>
    <el-col :xl="12" class="mb-xs-3">
      <video-player
        :height="height"
        :video-name="videoName"
        muted
      />
    </el-col>
  </el-row>
</template>

<script>
import VideoPlayer from '@/components/_v2/VideoPlayer'

export default {
  name: 'VideoQuickTour',

  components: {
    VideoPlayer
  },

  props: {
    videoName: {
      type: String,
      required: true
    },
    height: {
      type: String,
      default: '350'
    }
  }
}
</script>
