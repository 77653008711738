<template>

  <!-- Financing Breakdown -->
  <el-row :gutter="20">

    <!-- Left Side -->
    <el-col :span="18" class="position-relative">

      <chart-header>
        {{ $t('charts.financingSources') }}
        <template #right>
          <!-- Breakdown Switcher -->
          <breakdown-total-switcher size="xs" @change="showTotals = $event" />
          <!-- /Breakdown Switcher -->
        </template>
      </chart-header>

      <!-- Chart -->
      <financing-sources-chart
        :show-totals="showTotals"
        :chart-adjustment="chartAdjustment"
        chart-animations
        hide-title
      />
      <!-- /Chart -->

    </el-col>
    <!-- /Left Side -->

    <!-- Right Side -->
    <el-col :span="6">
      <div class="breakdown">

        <!-- Total Financing -->
        <ib-tooltip v-if="Math.abs(Number($store.state.idea.storyMode.setUp.financing.breakdown.totalFinancing)) >= 1.0e+7" :content="'Total Financing Income: ' + chartTooltipDisplay(Number($store.state.idea.storyMode.setUp.financing.breakdown.totalFinancing))" placement="right" :open-delay="0">
          <p class="total-costs">
            {{ chartValueFormatter(Number($store.state.idea.storyMode.setUp.financing.breakdown.totalFinancing)) }}
          </p>
        </ib-tooltip>

        <p v-else class="total-costs">
          {{ chartValueFormatter(Number($store.state.idea.storyMode.setUp.financing.breakdown.totalFinancing)) }}
        </p>
        <!-- /Total Financing -->

        <!-- Total Financing Title -->
        <p class="text-small">
          {{ $t('charts.totalFinancingIncome') }}
        </p>
        <!-- /Total Financing Title -->

        <!-- Details -->
        <div class="details">

          <!-- Top 3 Financings -->
          <p class="text-small">
            {{ $t('charts.topThreeFinancingIncomes') }}
          </p>
          <!-- /Top 3 Financings -->

          <!-- Financings -->
          <div v-for="(financing, index) in $store.state.idea.storyMode.setUp.financing.breakdown.topFinancings" :key="financing.id" class="flex">
            <div class="number">
              {{ index + 1 }}
            </div>
            <div class="name-and-amount">
              <ib-tooltip v-if="Math.abs(getAmount(financing)) >= 1.0e+7" :content="financing.name + ': ' + chartTooltipDisplay(getAmount(financing))" placement="right" :open-delay="0">
                <p class="bold">
                  {{ chartValueFormatter(getAmount(financing)) }}
                </p>
              </ib-tooltip>

              <p v-else class="bold">
                {{ chartValueFormatter(getAmount(financing)) }}
              </p>

              <p class="text-small">
                {{ financing.name }}
              </p>
            </div>
          </div>
          <!-- Financings -->

        </div>
        <!-- /Details -->

      </div>
    </el-col>
    <!-- /Right Side -->

  </el-row>
  <!-- /Financing Breakdown -->

</template>

<script>
import BreakdownTotalSwitcher from '@/components/Charts/Breakdown/BreakdownTotalSwitcher'
import ChartHeader from '@/views/Home/StoryMode/Components/BusinessPlan/ChartHeader'
import FinancingSourcesChart from '@/views/Home/StoryMode/SetUp/Financing/Charts/FinancingSourcesChart'
import MixinCalculations from '@/mixins/calculations'
import MixinCharts from '@/mixins/charts'
import MixinCurrencies from '@/mixins/currencies'

export default {
  name: 'FinancingBreakdown',

  components: {
    BreakdownTotalSwitcher,
    ChartHeader,
    FinancingSourcesChart
  },

  mixins: [
    MixinCalculations,
    MixinCharts,
    MixinCurrencies
  ],

  props: {
    chartAdjustment: {
      type: Object,
      default: () => ({
        chartHeight: 370,
        headingTitleFontSize: '22px',
        xLabelTitleFontSize: '12px',
        yLabelTitleFontSize: '14px',
        yaxisLabelsMinWidth: {
          breakDown: 250
        }
      })
    },
    switchSelector: {
      type: String,
      default: 'financing'
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      showTotals: false
    }
  },

  methods: {
    getAmount (finance) {
      if (finance.type === 'loc') {
        return (finance.howManyTimes * finance.averageWithdrawalAmount)
      }

      return finance.amount
    }
  }
}
</script>
