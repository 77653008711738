<template>
  <div class="switch-wrapper">
    <form-switcher
      v-model="period"
      :size="size"
      :options="[{
        title: $t('monthly'),
        value: 'monthly',
        callback: switchToMonthly
      },{
        title: $t('quarterly'),
        value: 'quarterly',
        callback: switchToQuarterly
      },{
        title: $t('yearly'),
        value: 'yearly',
        callback: switchToYearly
      }]"
      @change="$emit('change', period)"
    />
  </div>
</template>

<script>
import FormSwitcher from '@/components/_v2/Form/FormSwitcher'

export default {
  name: 'TimelinePeriodSwitcherBusinessPlan',

  components: {
    FormSwitcher
  },

  props: {
    size: {
      type: String,
      validator: value => {
        return ['xs', 'sm', 'md'].includes(value)
      },
      default: 'sm'
    }
  },

  data () {
    return {
      period: 'monthly'
    }
  },

  methods: {
    switchToMonthly () {
      this.$emit('switch-to-monthly')
    },

    switchToQuarterly () {
      this.$emit('switch-to-quarterly')
    },

    switchToYearly () {
      this.$emit('switch-to-yearly')
    }
  }
}
</script>
