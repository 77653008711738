<template>
  <video-quick-tour
    :height="videoHeight"
    video-name="Intro3_Journal"
  >
    <h4 class="my-3 my-xl-0 font-outfit-medium">
      {{ $t('pages.pricing.featuresName.businessPlan') }}
    </h4>
    <div v-html="$t('businessPlanQuickTourText')" />
  </video-quick-tour>
</template>

<script>
import VideoQuickTour from '@/components/_v2/VideoQuickTour'

export default {
  name: 'BusinessPlanQuickTour',

  components: {
    VideoQuickTour
  },

  props: {
    videoHeight: {
      type: String,
      default: '320'
    }
  }
}
</script>
