<template>
  <ib-tooltip popper-class="plan-popper">
    <!-- Tooltip Content -->
    <div
      slot="content"
      class="tooltip-content"
    >
      <p>{{ $t('pages.businessPlan.showHideNotes') }}</p>
    </div>
    <!-- /Tooltip Content -->
    <a
      class="more-details"
      :class="{'disabled': disabled, 'no-visible': !visible}"
      @click="$emit('click')"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6 2c-1.094 0-2 .906-2 2v16c0 1.094.906 2 2 2h12c1.094 0 2-.906 2-2V8l-6-6H6zm0 2h7v5h5v11H6V4zm2 8v2h8v-2H8zm0 4v2h8v-2H8z"/></svg>
    </a>
  </ib-tooltip>
</template>

<script>
export default {
  name: 'ToggleNotesButton',

  props: {
    disabled: {
      type: Boolean,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  }
}
</script>
<style scoped>
.no-visible {
  opacity: 0.6;
}
</style>
