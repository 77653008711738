<template>
  <vue-apex-charts
    ref="chart"
    type="line"
    :height="chartAdjustment.chartHeight"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
import EventBus from '@/event-bus'
import MixinCalculations from '@/mixins/calculations'
import MixinCharts from '@/mixins/charts'
import MixinCurrencies from '@/mixins/currencies'
import VueApexCharts from 'vue-apexcharts'
import responsiveChartOptions from '@/data/responsiveChartOptions'

export default {
  name: 'ProfitAndLosProjectionChart',

  components: { VueApexCharts },

  mixins: [MixinCalculations, MixinCharts, MixinCurrencies],

  props: {
    period: {
      type: String,
      default: 'monthly'
    },
    chartAdjustment: {
      type: Object,
      default: () => ({
        chartHeight: 370,
        headingTitleFontSize: '22px',
        xLabelTitleFontSize: '12px',
        yLabelTitleFontSize: '14px',
        yaxisLabelsMinWidth: {
          breakDown: 70
        }
      })
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    chartAnimations: {
      type: Boolean,
      default: false
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          background: '#fff',
          stacked: true,
          fontFamily: 'outfit-light,arial',
          animations: {
            enabled: this.chartAnimations,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            }
          }
        },
        title: {
          text: !this.hideTitle ? 'Profit and Loss Projection' : '',
          margin: 20,
          offsetX: 20,
          offsetY: 30,
          style: {
            fontSize: this.chartAdjustment.headingTitleFontSize,
            color: '#263238',
            fontFamily: 'outfit-light,arial'
          }
        },
        grid: {
          borderColor: 'transparent'
        },
        annotations: {
          position: 'back',
          yaxis: [
            {
              y: 0,
              borderColor: '#afafb1'
            }
          ]
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'flat',
            colors: {
              ranges: [{
                from: 0.001,
                to: 10000,
                color: '#4cda63'
              }, {
                from: -1000,
                to: 0,
                color: '#e53935'
              }]
            }
          }
        },
        colors: [
          '#4cda63', '#e53935', '#39A691'
        ],
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          curve: 'straight',
          width: [0, 0, 3],
          colors: ['#3e435e']
        },
        xaxis: {
          categories: this.getCategories(),
          labels: {
            show: true,
            style: {
              color: '#afafb1',
              fontSize: this.chartAdjustment.xLabelTitleFontSize,
              fontFamily: 'outfit-light,arial',
              cssClass: 'apexcharts-xaxis-label'
            }
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false,
            color: '#fafafa',
            height: 1,
            width: '100%',
            offsetX: 0,
            offsetY: 0
          },
          crosshairs: {
            show: true,
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 1,
              opacity: 0.4
            }
          }
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            formatter: this.formatChartYLabels,
            show: true,
            align: 'right',
            style: {
              color: '#afafb1',
              fontSize: this.chartAdjustment.xLabelTitleFontSize,
              fontFamily: 'outfit-light,arial',
              cssClass: 'apexcharts-yaxis-label',
              opacity: 0.7
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0
          },
          crosshairs: {
            show: false,
            width: 0,
            position: 'back',
            opacity: 0,
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 1,
              opacity: 0
            }
          }
        },
        fill: {

        },
        tooltip: {
          marker: {
            show: true
          },
          fillSeriesColor: false,
          y: {
            formatter: this.formatTooltip
          },
          x: {
            show: false
          },
          theme: 'dark'
        },
        legend: {
          show: false
        },
        responsive: responsiveChartOptions
      }
    }
  },

  computed: {
    series () {
      const series = [
        {
          name: this.$t('income'),
          type: 'column',
          data: []
        },
        {
          name: this.$t('expenses'),
          type: 'column',
          data: []
        },
        {
          name: this.$t('netProfit'),
          type: 'line',
          data: []
        }
      ]

      if (
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation &&
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.totalRevenue &&
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.totalExpenses &&
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.netProfit
      ) {
        series[0].data = this.createSeriesDataFromCalculation(this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.totalRevenue.data)
        series[1].data = this.createSeriesDataFromCalculation(this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.totalExpenses.data)
        series[2].data = this.createSeriesDataFromCalculation(this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.netProfit.data)
      }

      return series
    }
  },

  watch: {
    period () {
      this.$set(this.chartOptions.xaxis, 'categories', this.getCategories())
      this.$refs.chart.refresh()
    }
  },

  mounted () {
    EventBus.$on('idea-settings-update', () => {
      if (this.$refs.chart !== undefined) {
        this.$refs.chart.refresh()
      }
    })
  }
}
</script>
