<template>
  <!-- Chart -->
  <vue-apex-charts
    ref="chart"
    type="bar"
    :height="chartAdjustment.chartHeight"
    :options="chartOptions"
    :series="series"
  />
  <!-- /Chart -->
</template>

<script>
import EventBus from '@/event-bus'
import MixinCalculations from '@/mixins/calculations'
import MixinCharts from '@/mixins/charts'
import MixinCurrencies from '@/mixins/currencies'
import VueApexCharts from 'vue-apexcharts'

import responsiveChartOptions from '@/data/responsiveChartOptions'

export default {
  name: 'RevenueOverTimeChart',

  components: { VueApexCharts },

  mixins: [MixinCharts, MixinCalculations, MixinCurrencies],

  props: {
    period: {
      type: String,
      default: 'monthly'
    },
    chartAdjustment: {
      type: Object,
      default: () => ({
        chartHeight: 370,
        headingTitleFontSize: '22px',
        xLabelTitleFontSize: '12px',
        yLabelTitleFontSize: '14px',
        yaxisLabelsMinWidth: {
          breakDown: 250
        }
      })
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    chartAnimations: {
      type: Boolean,
      default: false
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          background: '#fff',
          stacked: true,
          fontFamily: 'outfit-light,arial',
          animations: {
            enabled: this.chartAnimations,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          }
        },
        title: {
          text: !this.hideTitle ? 'Revenue over time' : '',
          margin: 20,
          offsetX: 20,
          offsetY: 30,
          style: {
            fontSize: this.chartAdjustment.headingTitleFontSize,
            color: '#263238',
            fontFamily: 'outfit-light,arial'
          }
        },
        colors: ['#4cda63', '#4cda63'],
        grid: {
          borderColor: 'transparent'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['transparent']
        },
        xaxis: {
          categories: this.getCategories(),
          labels: {
            show: true,
            style: {
              color: '#afafb1',
              fontSize: this.chartAdjustment.xLabelTitleFontSize,
              fontFamily: 'outfit-light,arial',
              cssClass: 'apexcharts-xaxis-label'
            }
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 1,
              opacity: 0.4
            }
          }
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            formatter: this.formatChartYLabels,
            show: true,
            align: 'right',
            // minWidth: this.chartAdjustment.yaxisLabelsMinWidth.timeLine,
            // maxWidth: 120,
            style: {
              color: '#afafb1',
              fontSize: this.chartAdjustment.xLabelTitleFontSize,
              fontFamily: 'outfit-light,arial',
              cssClass: 'apexcharts-yaxis-label',
              opacity: 0.7
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0
          },
          crosshairs: {
            show: false,
            width: 0,
            position: 'back',
            opacity: 0,
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 1,
              opacity: 0
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          marker: {
            show: false
          },
          y: {
            title: {
              formatter (seriesName) {
                return seriesName
              }
            },
            formatter: this.formatTooltip
          },
          x: {
            show: false
          },
          theme: 'dark'
        },
        responsive: responsiveChartOptions
      }
    }
  },

  computed: {
    series () {
      const series = [{
        name: '',
        data: []
      }]

      if (this.$store.state.idea.storyMode.forecast.revenueStreams.timeline.data) {
        series[0].data = this.createSeriesDataFromCalculation(
          this.$store.state.idea.storyMode.forecast.revenueStreams.timeline.data
        )
      }

      return series
    }
  },

  watch: {
    period () {
      this.$set(this.chartOptions.xaxis, 'categories', this.getCategories())
      this.$refs.chart.refresh()
    }
  },

  mounted () {
    EventBus.$on('idea-settings-update', () => {
      if (this.$refs.chart !== undefined) {
        this.$refs.chart.refresh()
      }
    })
  },

  methods: {
    formatTooltip (value) {
      if (value) {
        // TODO - Check if we should show decimal on timeline
        const decimalPlaces = Number.isInteger(value) || value.toString().substr(value.toString().indexOf('.') + 1).startsWith('00') ? 0 : 0

        return this.currencySymbol + '' + this.getFormattedAmount(value, decimalPlaces)
      }
    }
  }
}
</script>
