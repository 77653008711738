<template>
  <div class="video-player-wrapper">
    <video ref="introVideo" preload="metadata" playsinline width="100%" :height="height" :controls="controls" :muted="muted" :autoplay="autoplay" :loop="loop">
      <source :src="videoUrl + '.webm#t=0.1'" type="video/webm">
      <source :src="videoUrl + '.mp4#t=0.1'" type="video/mp4">
      <source :src="videoUrl + '.ogv#t=0.1'" type="video/ogv">
      Your browser does not support the video tag.
    </video>
    <div
      v-if="isVideoPaused"
      class="video-button"
      @click="videoPlayToggle"
    />
  </div>
</template>

<script>
import EventBus from '@/event-bus'

export default {
  name: 'VideoPlayer',

  props: {
    videoName: {
      type: String,
      required: true
    },
    controls: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: 'auto'
    },
    muted: {
      type: Boolean,
      default: false
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isVideoPaused: true
    }
  },

  computed: {
    videoUrl () {
      return '/uploads/videos/' + this.videoName
    }
  },

  mounted () {
    EventBus.$on('video-play', () => {
      if (this.$refs.introVideo && this.$refs.introVideo.paused) {
        this.$refs.introVideo.play()
      }
    })

    EventBus.$on('video-pause', () => {
      if (this.$refs.introVideo && !this.$refs.introVideo.paused) {
        this.$refs.introVideo.pause()
      }
    })

    this.$refs.introVideo.onpause = () => {
      this.isVideoPaused = true
    }
    this.$refs.introVideo.onplay = () => {
      this.isVideoPaused = false

      this.$gtm.trackEvent({
        event: 'playVideo'
      })
    }
  },

  beforeDestroy () {
    this.$refs.introVideo.pause()
  },

  methods: {
    videoPlayToggle () {
      if (this.$refs.introVideo.paused) {
        this.isVideoPaused = false

        return this.$refs.introVideo.play()
      }

      this.$refs.introVideo.pause()
      this.isVideoPaused = true
    }
  }
}
</script>

<style lang="scss" scoped>
.video-player-wrapper {
  position: relative;
  cursor: pointer;

  video {
    height: 200px;

    @include media-breakpoint-up($md) {
      height: auto;
    }
  }
}

.video-button {
  background-image: url("../../assets/img/video/ib-play.png");
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  opacity: 0.7;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
</style>
