<template>
  <div
    class="editable-wrapper"
    :class="classesWrapper"
  >
    <!-- If Disabled -->
    <template v-if="disabled">
      <component :is="tag">
        {{ content }}
      </component>
    </template>
    <!-- If Not Disabled -->
    <template v-else>
      <component
        :is="tag"
        class="px-1 py-2"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
        @click="focusField"
      >
        <template v-if="!editMode">
          {{ content }}
        </template>
        <!-- Input field -->
        <input
          v-if="editMode"
          :ref="randomRef"
          v-model.trim="content"
          :class="classes"
          class="input-edit"
          @keydown.enter="onEnter"
          @keydown.esc="onEsc"
          @blur="onBlur"
        >
      </component>
    </template>
  </div>
</template>

<script>
export default {
  name: 'EditableTitle',

  props: {
    value: {
      required: true,
      validator: value => typeof value === 'string' || value === null,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'h2'
    },
    textCenter: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      editMode: false,
      hover: false,
      startValue: this.value,
      firstLoad: true
    }
  },

  computed: {
    content: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },

    classes () {
      return {
        'text-center': this.textCenter
      }
    },

    classesWrapper () {
      return {
        hover: this.hover && !this.editMode
      }
    },

    contentLength () {
      return this.content.length
    },

    randomRef () {
      return Math.random()
        .toString(36)
        .substring(7)
    }
  },

  updated () {
    if (this.firstLoad) {
      this.startValue = this.value
      this.firstLoad = false
    }
  },

  methods: {
    focusField () {
      this.editMode = true
      this.$nextTick(() => {
        this.$refs[this.randomRef].focus()
      })
    },

    onBlur () {
      this.editMode = false

      if (!this.contentLength) {
        this.contentToStartValue()

        return
      }

      this.updateStartValue()
      this.$emit('save', this.content)
    },

    onEnter () {
      if (!this.contentLength) {
        this.contentToStartValue()
      }
      this.editMode = false
    },

    onEsc () {
      this.contentToStartValue()
      this.editMode = false
    },

    contentToStartValue () {
      this.content = this.startValue
    },

    updateStartValue () {
      this.startValue = this.content
    }
  }
}
</script>

<style scoped lang="scss">
.editable-wrapper {
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  border: 2px solid transparent;
  border-radius: 3px;
  cursor: text;

  &.hover {
    background: #e8e9ed;
  }
}

.input-edit {
  width: 100%;
  border: none;
  background: transparent;
  margin: 0 !important;
  padding: 0 !important;
  font-family: inherit !important;
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
  line-height: inherit;
}

.text-center {
  text-align: center;
}
</style>
