<template>
  <div
    class="ownership-table-color"
    :style="{backgroundColor: color}"
  />
</template>

<script>
export default {
  name: 'OwnersListTableColor',

  props: {
    color: {
      type: String,
      default: '#0052CC'
    }
  }
}
</script>

<style scoped lang="scss">
.ownership-table-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
</style>
