<template>
  <ib-card
    :closable="closable"
    :animation="animation"
    class="quick-tour-card-wrapper d-flex flex-column"
    :class="classes"
    @close="onclose"
  >
    <slot />

    <!-- Divider -->
    <ib-divider block class="my-3" />
    <!-- /Divider -->

    <!-- Button -->
    <div class="d-flex justify-content-end">
      <ib-button
        class="text-uppercase"
        submit-button
        @click="onclose"
      >
        {{ $t('letsStart') }}
      </ib-button>
    </div>
    <!-- /Button -->
  </ib-card>
</template>

<script>
import EventBus from '@/event-bus'
import { scrollToTop } from '@/helpers/scrollHelpers'

export default {
  name: 'QuickTourCardWrapper',

  props: {
    active: {
      type: Boolean,
      default: false
    },
    closable: {
      type: Boolean,
      default: false
    },
    animation: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      if (this.active) {
        return ['active', 'mb-4', 'mt-4']
      }

      return null
    }
  },

  watch: {
    'active' (active) {
      const event = active ? 'video-play' : 'video-pause'
      EventBus.$emit(event)
    }
  },

  created () {
    setTimeout(() => {
      EventBus.$emit('video-play')
    }, 100)
  },

  methods: {
    onclose () {
      scrollToTop('#app')

      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.quick-tour-card-wrapper {
  opacity: 0;
  height: 0;
  transform: scale(0);
  transform-origin: 100% 0 0;

  &.active {
    opacity: 1;
    height: auto;
    transform: scale(1);
    padding: 40px 15px 20px;
    transition: transform 0.35s;

    @include media-breakpoint-up($md) {
      padding: 40px 40px 25px;
    }
  }
}
</style>
