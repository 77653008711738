<template>

  <!-- Loader -->
  <div class="loader">
    <img alt="Loader" src="@/assets/img/util/loader.gif">
  </div>
  <!-- /Loader -->

</template>

<script>
export default {
  name: 'Loader'
}
</script>
