<template>

  <!-- Revenue Streams Table -->
  <div class="position-relative bg-white">
    <div>
      <!-- Title & Period Switcher -->
      <div class="d-flex flex-column flex-md-row align-items-center align-items-md-start justify-content-between title-and-switcher-container">

        <!-- Title -->
        <h3 class="m-0 mb-3 mb-md-0 text-center text-md-left">
          {{ $t('charts.revenueSummary') }}
        </h3>
        <!-- /Title -->

        <!-- Period Switcher -->
        <div
          v-if="!tableAdjustment.journalPage"
          class="period-switcher"
        >
          <table-period-switcher
            @change="period = $event"
          />
        </div>
        <!-- /Period Switcher -->

      </div>
      <!-- /Title & Period Switcher -->

      <!-- Table -->
      <div id="monthly-table">
        <el-table
          ref="table"
          :data="tableData"
          row-key="id"
          :border="false"
          :stripe="false"
          size="small"
        >

          <!-- Fixed Column -->
          <el-table-column
            class-name="main-cells-1"
            label-class-name=""
            :fixed="!$breakpoint.xs"
            prop="name"
            :label="$t('tables.revenueStreams.header')"
            :width="tableFirstColumnWidth"
          />
          <!-- /Fixed Column -->

          <!-- Period Columns -->
          <el-table-column
            v-for="column in columns"
            :key="column.id"
            class-name="main-cells"
            label-class-name="table-labels"
            align="right"
            :label="column.label"
            :min-width="tableAdjustment.columnWidth"
          >
            <template slot-scope="scope">
              <span class="currency">{{ getCellSymbol(scope, column) }}</span>
              <span>{{ scope.row.values[column.name] ? getRoundedFormattedAmount(scope.row.values[column.name]) : '' }}</span>
            </template>
          </el-table-column>
          <!-- /Period Columns -->

        </el-table>
      </div>
      <!-- /Table -->

    </div>
  </div>
  <!-- /Revenue Streams Table -->
</template>

<script>
import MixinCalculations from '@/mixins/calculations'
import MixinCurrencies from '@/mixins/currencies'
import MixinTable from '@/mixins/table'
import TablePeriodSwitcher from '@/components/Charts/Table/TablePeriodSwitcher'

export default {
  name: 'RevenueStreamsTable',

  components: {
    TablePeriodSwitcher
  },

  mixins: [
    MixinCalculations,
    MixinCurrencies,
    MixinTable
  ],

  props: {
    tableAdjustment: {
      type: Object,
      default: () => ({
        journalPage: false,
        period: 'monthly',
        tableFirstColumnWidth: 250,
        columnWidth: 118
      })
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      period: this.tableAdjustment.period
    }
  },

  computed: {
    tableData () {
      let data = []

      if (
        this.$store.state.idea.storyMode.forecast.revenueStreams.table &&
        this.$store.state.idea.storyMode.forecast.revenueStreams.table[this.period]
      ) {
        data = this.$store.state.idea.storyMode.forecast.revenueStreams.table[this.period]
      }

      return data
    },

    columns () {
      let columns = []

      if (
        this.$store.state.idea.storyMode.forecast.revenueStreams.table &&
        this.$store.state.idea.storyMode.forecast.revenueStreams.table.columns[this.period]
      ) {
        columns = this.$store.state.idea.storyMode.forecast.revenueStreams.table.columns[this.period]
      }

      return columns
    }
  },

  methods: {
    getDateFormat () {
      let format = 'MMM YY'

      if (this.period === 'quarterly') {
        format = '[Q]Q YY'
      }

      if (this.period === 'yearly') {
        format = 'YYYY'
      }

      return format
    },

    getCellSymbol (scope, column) {
      return (scope.row.values[column.name] && scope.row.currencySymbolIncluded) ? this.currencySymbol : (scope.row.percentageSymbolIncluded && typeof scope.row.values[column.name] !== 'undefined' && scope.row.values[column.name] !== 0 ? '%' : '')
    }
  }
}
</script>
<style scoped>
.period-switcher {
  max-width: 150px;
  min-width: 150px;
}

.title-and-switcher-container {
  padding: 0 30px;
}
</style>
