<template>
  <div class="switch-wrapper">
    <form-switcher
      v-model="totals"
      :size="size"
      :options="[{
        title: $t('detailed'),
        value: false
      },{
        title: $t('totals'),
        value: true
      }]"
      @change="$emit('change', totals)"
    />
  </div>
</template>

<script>
import FormSwitcher from '@/components/_v2/Form/FormSwitcher'

export default {
  name: 'BreakdownTotalSwitcher',

  components: {
    FormSwitcher
  },

  props: {
    size: {
      type: String,
      validator: value => {
        return ['xs', 'sm', 'md'].includes(value)
      },
      default: 'sm'
    }
  },

  data () {
    return {
      totals: false
    }
  }
}
</script>
