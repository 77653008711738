<template>
  <div
    v-if="$store.state.idea.storyMode.common.completedSteps.startupCostsCompleted === true && isStartupCostsVisible"
    class="journal-content dynamic-height"
    :class="{'dynamic-height': !isOption(startupCostData, 'breakdownSection') && !isOption(startupCostData, 'timelineSection') && !isOption(startupCostData, 'tableSection')}"
  >
    <div class="content-menus">
      <div v-if="moreVisible === true" class="view-type">

        <template v-if="startupCosts.length !== 0">
          <a
            class="orange"
            :class="{'orange-off': !isOption(startupCostData, 'breakdownSection')}"
            @click="enableBreakdown"
          >
            {{ $t('pages.businessPlan.sections.breakdown') }}
          </a>
          <a
            class="orange"
            :class="{'orange-off': !isOption(startupCostData, 'timelineSection')}"
            @click="enableTimeline"
          >
            {{ $t('pages.businessPlan.sections.timeline') }}
          </a>
          <a
            class="orange"
            :class="{'orange-off': !isOption(startupCostData, 'tableSection')}"
            @click="enableTable"
          >
            {{ $t('pages.businessPlan.sections.table') }}
          </a>
        </template>
        <p v-if="startupCosts.length === 0">
          {{ $t('pages.businessPlan.sections.youDontHaveAnyDataOnThisPage') }}
        </p>
      </div>
      <div v-if="canEdit" class="mini-actions">
        <toggle-page-items-button
          :visible="moreVisible"
          @click="toggleMore"
        />
        <toggle-view-button
          :disabled="!cardExist"
          :horizontal="!isOption(startupCostData, 'gridListView')"
          @click="toggleRotate"
        />
        <toggle-notes-button
          :disabled="!moreDetails"
          :visible="isOption(startupCostData, 'moreDetailsDisplay')"
          @click="enableMoreDetails"
        />
        <edit-section-link route-name="business-guide-set-up-startup-costs" />
      </div>
    </div>

    <div class="sub chapter">
      <header>
        <!-- Title section -->
        <editable-title
          v-model="startupCostData.title"
          tag="h1"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Title section -->
        <hr>
        <!-- Description section -->
        <editable-description
          v-model="startupCostData.subTitle"
          :empty-text="$t('addMoreDetails.addNoteText')"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Description section -->
      </header>

      <template v-if="startupCosts.length !==0">
        <!-- Startup Costs Breakdown -->
        <div v-if="isOption(startupCostData, 'breakdownSection')" class="switcher-inner-wrapper breakdown-chart">
          <startup-costs-breakdown
            :update-chart="updateCharts"
            :chart-adjustment="chartAdjustment"
          />
        </div>
        <!-- /Startup Costs Breakdown -->

        <!-- Startup Costs Timeline -->
        <div v-if="isOption(startupCostData, 'timelineSection')" class="timeline-chart">
          <startup-costs-timeline
            :update-chart="updateCharts"
            :chart-adjustment="chartAdjustment"
          />
        </div>
        <!-- /Startup Costs Timeline -->

        <!-- Startup Costs Table -->
        <div v-if="isOption(startupCostData, 'tableSection')" class="table-chart">
          <startup-costs-table
            :update-chart="updateCharts"
            :table-adjustment="tableAdjustment"
          />
        </div>
        <!-- /Startup Costs Table -->
      </template>

      <!-- More Details section -->
      <template v-if="moreDetails && isOption(startupCostData, 'moreDetailsDisplay')">
        <div class="details">
          <editable-title
            v-model="startupCostData.moreDetailsTitle"
            class="bold"
            tag="p"
            :disabled="!canEdit"
            @save="onSave"
          />
          <p v-html="moreDetails" />
        </div>
      </template>
      <!-- More Details section -->
    </div>
  </div>
</template>

<script>
import EditSectionLink from '@/views/Home/StoryMode/Components/BusinessPlan/EditSectionLink'
import EditableDescription from '@/components/_v2/EditableDescription'
import EditableTitle from '@/components/_v2/EditableTitle'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import StartupCostsBreakdown from '@/views/Home/StoryMode/SetUp/StartupCosts/Charts/StartupCostsBreakdown'
import StartupCostsTable from '@/views/Home/StoryMode/SetUp/StartupCosts/Tables/StartupCostsTable'
import StartupCostsTimeline from '@/views/Home/StoryMode/SetUp/StartupCosts/Charts/StartupCostsTimeline'
import ToggleNotesButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleNotesButton'
import TogglePageItemsButton from '@/views/Home/StoryMode/Components/BusinessPlan/TogglePageItemsButton'
import ToggleViewButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleViewButton'
import journalMethods from '@/mixins/journalMethods'

export default {
  name: 'StartupCosts',

  components: {
    ToggleNotesButton,
    TogglePageItemsButton,
    ToggleViewButton,
    EditSectionLink,
    StartupCostsBreakdown,
    StartupCostsTimeline,
    StartupCostsTable,
    EditableTitle,
    EditableDescription
  },

  mixins: [
    journalMethods,
    MixinIdeaRoles
  ],

  props: {
    allPagesTextData: {
      type: Object,
      default: () => ({})
    },
    shareMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      cardExist: false,
      startupCostData: {},
      moreVisible: false,
      updateCharts: true,

      startupCosts: this.$store.state.idea.storyMode.setUp.startupCosts.startupCosts,
      moreDetails: this.$store.state.idea.storyMode.common.moreDetails.startupCostMoreDetails,

      chartAdjustment: {
        chartHeight: 260,
        headingTitleFontSize: '18px',
        xLabelTitleFontSize: '10px',
        yLabelTitleFontSize: '12px',
        yaxisLabelsMinWidth: {
          breakDown: 0,
          timeLine: 0
        }
      },
      tableAdjustment: {
        journalPage: true,
        period: 'yearly',
        tableFirstColumnWidth: 250,
        columnWidth: 118
      },
      switchSelector: 'payroll'
    }
  },

  computed: {
    isStartupCostsVisible () {
      const index = this.$store.state.idea.journal.journalSwitchBars.indexOf(
        this.$store.state.idea.journal.journalSwitchBars.find(journalElement => journalElement.sectionName === 'StartupCosts')
      )

      return !this.$store.state.idea.journal.journalSwitchBars[index].options.includes('switch')
    },

    isTitleEnabled () {
      return this.startupCostData.title
    },

    isMoreDetailsTitleEnabled () {
      return this.startupCostData.moreDetailsTitle
    }
  },

  mounted () {
    this.startupCostData = Object.assign({}, this.allPagesTextData)
  },

  methods: {
    toggleRotate () {
      if (this.cardExist) {
        this.toggleOption(this.startupCostData, 'gridListView')
        this.startupCostData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    enableMoreDetails () {
      if (this.moreDetails) {
        this.toggleOption(this.startupCostData, 'moreDetailsDisplay')
        this.startupCostData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    onSave () {
      this.$emit('save', this.startupCostData)
    },

    enableTimeline () {
      this.toggleOption(this.startupCostData, 'timelineSection')
      this.startupCostData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    },

    enableBreakdown () {
      this.toggleOption(this.startupCostData, 'breakdownSection')
      this.startupCostData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    },

    enableTable () {
      this.toggleOption(this.startupCostData, 'tableSection')
      this.startupCostData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    }
  }
}
</script>
