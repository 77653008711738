<template>
  <vue-apex-charts
    ref="chart"
    type="bar"
    :height="chartAdjustment.chartHeight"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
import EventBus from '@/event-bus'
import MixinCurrencies from '@/mixins/currencies'
import VueApexCharts from 'vue-apexcharts'

import { formatChartCategories, labelAlignment, labelMaxWidth, labelMinWidth } from '@/helpers/chartHelpers'
import responsiveChartOptions from '@/data/responsiveChartOptions'

export default {
  name: 'DistributionOfDirectCostsChart',

  components: {
    VueApexCharts
  },

  mixins: [MixinCurrencies],

  props: {
    chartAdjustment: {
      type: Object,
      default: () => ({
        chartHeight: 370,
        headingTitleFontSize: '22px',
        xLabelTitleFontSize: '12px',
        yLabelTitleFontSize: '14px',
        yaxisLabelsMinWidth: {
          breakDown: 250
        }
      })
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    chartAnimations: {
      type: Boolean,
      default: false
    },
    formatLabels: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          background: '#fff',
          stacked: true,
          fontFamily: 'outfit-light,arial',
          animations: {
            enabled: this.chartAnimations,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        stroke: {
          width: 2,
          colors: ['#fff']
        },
        title: {
          text: !this.hideTitle ? 'Distribution of direct costs' : '',
          margin: 20,
          offsetX: 20,
          offsetY: 30,
          style: {
            fontSize: this.chartAdjustment.headingTitleFontSize,
            color: '#263238',
            fontFamily: 'outfit-light,arial'
          }
        },
        grid: {
          borderColor: 'transparent'
        },
        colors: ['#0291ff', '#0291ff'],
        dataLabels: {
          enabled: false
        },
        xaxis: {
          axisBorder: {
            show: false
          },
          lines: {
            show: false
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: 'transparent',
            offsetX: 0
          },
          categories: [],
          labels: {
            formatter: this.formatChartLabels,
            minHeight: undefined,
            maxHeight: 40,
            style: {
              fontSize: this.chartAdjustment.xLabelTitleFontSize,
              fontFamily: 'outfit-regular',
              cssClass: 'apexcharts-xaxis-label'
            }
          },
          offsetY: 40,
          tickAmount: 7
        },
        yaxis: {
          tickAmount: 10,
          axisBorder: {
            show: false,
            color: '#dadada',
            offsetX: 0,
            offsetY: 0
          },
          labels: {
            show: true,
            align: labelAlignment,
            minWidth: this.formatLabels ? labelMinWidth : 0,
            maxWidth: this.formatLabels ? labelMaxWidth : 160,
            style: {
              color: '#292f4d',
              fontSize: this.chartAdjustment.yLabelTitleFontSize,
              fontFamily: 'outfit-light,arial',
              cssClass: 'apexcharts-yaxis-label'
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0
          },
          crosshairs: {
            show: false,
            width: 0,
            position: 'back',
            opacity: 0,
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 1,
              opacity: 0
            }
          }
        },
        tooltip: {
          marker: {
            show: false
          },
          y: {
            title: {
              formatter (seriesName) {
                return seriesName
              }
            },
            formatter: this.formatTooltip
          },
          x: {
            show: false
          },
          followCursor: false,
          theme: 'dark'
        },
        fill: {
          opacity: 1
        },
        legend: {
          show: false,
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 20
        },
        responsive: responsiveChartOptions
      }
    }
  },

  computed: {
    series () {
      let result = []

      if (this.$store.state.idea.storyMode.forecast.directCosts.breakdown.categories) {
        this.$set(
          this.chartOptions.xaxis,
          'categories',
          formatChartCategories(this.$store.state.idea.storyMode.forecast.directCosts.breakdown.categories)
        )
      }

      if (this.$store.state.idea.storyMode.forecast.directCosts.breakdown.series) {
        result = this.$store.state.idea.storyMode.forecast.directCosts.breakdown.series
      }

      return result
    }
  },

  mounted () {
    EventBus.$on('idea-settings-update', () => {
      if (this.$refs.chart !== undefined) {
        this.$refs.chart.refresh()
      }
    })
  },

  methods: {
    formatChartLabels (value) {
      // let returnValue = (value / 1000) % 1 !== 0 ? (value / 1000).toFixed(3) : (value / 1000).toFixed(0)
      return this.currencySymbol + '' + this.getRoundedFormattedAmountForCharts(value, 1)
    },

    formatTooltip (value) {
      if (value) {
        return this.currencySymbol + '' + this.getFormattedAmount(value)
      }
    }
  }
}
</script>
