<template>
  <div
    v-if="$store.state.idea.storyMode.common.completedSteps.productCompleted === true && isProductVisible"
    class="journal-content dynamic-height"
    :class="{'dynamic-height': productItems.length > 5 }"
  >
    <div class="content-menus">
      <div v-if="moreVisible === true" class="view-type">
        <a
          v-if="productItems.length !== 0"
          :class="{'orange-off': !isOption(productData, 'productsDisplay')}"
          class="orange"
          @click="enableProducts"
        >
          {{ $t('pages.businessPlan.sections.product.productItems') }}
        </a>

        <a
          v-if="sellingProposition.length !== 0"
          :class="{'orange-off': !isOption(productData, 'propositionDisplay')}"
          class="orange"
          @click="enableProposition"
        >
          {{ $t('pages.businessPlan.sections.product.sellingProposition') }}
        </a>

        <p v-if="productItems.length === 0 && sellingProposition.length === 0">
          {{ $t('pages.businessPlan.sections.youDontHaveAnyDataOnThisPage') }}
        </p>
      </div>
      <div v-if="canEdit" class="mini-actions">
        <toggle-page-items-button
          :visible="moreVisible"
          @click="toggleMore"
        />
        <toggle-view-button
          :disabled="!cardExist"
          :horizontal="!isOption(productData, 'gridListView')"
          @click="toggleRotate"
        />
        <toggle-notes-button
          :disabled="!moreDetails"
          :visible="isOption(productData, 'moreDetailsDisplay')"
          @click="enableMoreDetails"
        />
        <edit-section-link route-name="business-guide-concept-product" />
      </div>
    </div>

    <div class="sub chapter">
      <header>
        <!-- Title section -->
        <editable-title
          v-model="productData.title"
          tag="h1"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Title section -->
        <hr>
        <!-- Description section -->
        <editable-description
          v-model="productData.subTitle"
          :empty-text="$t('addMoreDetails.addNoteText')"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Description section -->
      </header>

      <template v-if="sellingProposition.length !== 0">
        <template v-if="isOption(productData, 'propositionDisplay')">
          <p class="unique-selling-prop-title font-outfit-medium">
            {{ $t('pages.businessPlan.sections.product.uniqueSellingProposition') }}
          </p>
          <ul v-for="(proposition, index) in sellingProposition" :key="index" class="selling-prop">
            <li>{{ proposition.content }}</li>
          </ul>
        </template>
      </template>

      <template v-if="productItems.length !== 0">
        <div
          v-if="isOption(productData, 'productsDisplay')"
          class="table-wrapper"
          :class="{'list-view': !isOption(productData, 'gridListView')}"
        >
          <div
            v-for="product in productItems"
            :key="product.id"
            class="journal-card product-card"
          >

            <div
              class="card-top"
              :class="{'default': !product.image }"
              :style="cardImageBySize(product.image, 'medium')"
            />

            <div class="card-bottom">
              <h3>{{ product.name }}</h3>
              <p class="pre-formatted desc">
                {{ product.description }}
              </p>
              <div class="bottom-segment">
                <p class="cat-desc">
                  {{ $t('pages.businessPlan.sections.product.keyDifferentiators') }}
                </p>
                <div class="category" :class="{ 'no-diff': !product.differentiators }">
                  <p class="title">
                    {{ product.differentiators ? product.differentiators : 'No differentiators' }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- More Details section -->
      <template v-if="moreDetails && isOption(productData, 'moreDetailsDisplay')">
        <div class="details">
          <editable-title
            v-model="productData.moreDetailsTitle"
            class="bold"
            tag="p"
            :disabled="!canEdit"
            @save="onSave"
          />
          <p v-html="moreDetails" />
        </div>
      </template>
      <!-- More Details section -->
    </div>
  </div>
</template>

<script>
import EditSectionLink from '@/views/Home/StoryMode/Components/BusinessPlan/EditSectionLink'
import EditableDescription from '@/components/_v2/EditableDescription'
import EditableTitle from '@/components/_v2/EditableTitle'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import ToggleNotesButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleNotesButton'
import TogglePageItemsButton from '@/views/Home/StoryMode/Components/BusinessPlan/TogglePageItemsButton'
import ToggleViewButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleViewButton'
import journalMethods from '@/mixins/journalMethods'

export default {
  name: 'Product',

  components: {
    EditSectionLink,
    EditableDescription,
    EditableTitle,
    ToggleNotesButton,
    TogglePageItemsButton,
    ToggleViewButton
  },

  mixins: [
    journalMethods,
    MixinIdeaRoles
  ],

  props: {
    allPagesTextData: {
      type: Object,
      default: () => ({})
    },
    shareMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      cardExist: true,
      productData: {},
      moreVisible: false,
      productItems: this.$store.state.idea.storyMode.concept.product.products,
      sellingProposition: this.$store.state.idea.storyMode.concept.product.uniqueSellingPropositions,
      moreDetails: this.$store.state.idea.storyMode.common.moreDetails.productMoreDetails
    }
  },

  computed: {
    isProductVisible () {
      const index = this.$store.state.idea.journal.journalSwitchBars.indexOf(
        this.$store.state.idea.journal.journalSwitchBars.find(journalElement => journalElement.sectionName === 'Product')
      )

      return !this.$store.state.idea.journal.journalSwitchBars[index].options.includes('switch')
    },

    isTitleEnabled () {
      return this.productData.title
    },

    isMoreDetailsTitleEnabled () {
      return this.productData.moreDetailsTitle
    }
  },

  mounted () {
    this.productData = Object.assign({}, this.allPagesTextData)
  },

  methods: {
    toggleRotate () {
      if (this.cardExist) {
        this.toggleOption(this.productData, 'gridListView')
        this.productData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    enableMoreDetails () {
      if (this.moreDetails) {
        this.toggleOption(this.productData, 'moreDetailsDisplay')
        this.productData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    onSave () {
      this.$emit('save', this.productData)
    },

    enableProducts () {
      this.toggleOption(this.productData, 'productsDisplay')
      this.productData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    },

    enableProposition () {
      this.toggleOption(this.productData, 'propositionDisplay')
      this.productData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    }
  }
}
</script>
