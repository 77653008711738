<template>

  <!-- Overhead Timeline -->
  <el-row :gutter="20">

    <!-- Left Side -->
    <el-col :span="18" class="position-relative">
      <chart-header>
        {{ $t('charts.otherOverheadsOverTime') }}
        <template #right>
          <!-- Timeline Period Switcher -->
          <timeline-period-switcher-business-plan size="xs" @change="switchChartPeriod($event)" />
          <!-- /Timeline Period Switcher -->
        </template>
      </chart-header>
      <!-- Chart -->
      <other-overheads-over-time-chart
        :chart-adjustment="chartAdjustment"
        :period="period"
        chart-animations
        hide-title
      />
      <!-- /Chart -->
    </el-col>
    <!-- /Left Side -->

    <!-- Right Side -->
    <timeline-right-side v-if="$store.state.idea.storyMode.forecast.otherOverheads.timeline.total
                           && $store.state.idea.storyMode.forecast.otherOverheads.timeline.byYear"
                         :title="$t('totalOtherOverheads')"
                         :total="$store.state.idea.storyMode.forecast.otherOverheads.timeline.total"
                         :by-year="$store.state.idea.storyMode.forecast.otherOverheads.timeline.byYear"
    />
    <!-- /Right Side -->

  </el-row>
  <!-- /Overhead Timeline -->

</template>

<script>
import ChartHeader from '@/views/Home/StoryMode/Components/BusinessPlan/ChartHeader'
import MixinCalculations from '@/mixins/calculations'
import MixinCharts from '@/mixins/charts'
import MixinCurrencies from '@/mixins/currencies'
import OtherOverheadsOverTimeChart from '@/views/Home/StoryMode/Forecast/OtherOverheads/Charts/OtherOverheadsOverTimeChart'
import TimelinePeriodSwitcherBusinessPlan from '@/components/Charts/Timeline/TimelinePeriodSwitcherBusinessPlan.vue'
import TimelineRightSide from '@/components/Charts/Timeline/TimelineRightSide'

export default {
  name: 'OtherOverheadTimeline',

  components: {
    TimelinePeriodSwitcherBusinessPlan,
    ChartHeader,
    OtherOverheadsOverTimeChart,
    TimelineRightSide
  },

  mixins: [
    MixinCalculations,
    MixinCharts,
    MixinCurrencies
  ],

  props: {
    updateChart: {
      type: Boolean
    },
    chartAdjustment: {
      type: Object,
      default: () => ({
        chartHeight: 370,
        headingTitleFontSize: '22px',
        xLabelTitleFontSize: '12px',
        yLabelTitleFontSize: '12px',
        yaxisLabelsMinWidth: {
          timeLine: 70
        }
      })
    },
    switchSelector: {
      type: String,
      default: 'other-overheads'
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      period: 'monthly'
    }
  },

  methods: {
    switchChartPeriod (period) {
      this.$set(this, 'period', period)
    }
  }
}
</script>
