<template>

  <!-- Startup Cost Timeline -->
  <div class="switcher-inner-wrapper">
    <el-row :gutter="20">

      <!-- Left Side -->
      <el-col :span="18" class="position-relative">
        <chart-header>
          {{ $t('charts.startupCostsOverTime') }}
          <template #right>
            <!-- Timeline Period Switcher -->
            <timeline-period-switcher-business-plan size="xs" @change="switchChartPeriod($event)" />
            <!-- /Timeline Period Switcher -->
          </template>
        </chart-header>

        <!-- Chart -->
        <startup-costs-over-time-chart
          :period="period"
          :chart-adjustment="chartAdjustment"
          chart-animations
          hide-title
        />
        <!-- /Chart -->

      </el-col>
      <!-- /Left Side -->

      <!-- Right Side -->
      <timeline-right-side
        v-if="$store.state.idea.storyMode.setUp.startupCosts.timeline &&
          $store.state.idea.storyMode.setUp.startupCosts.timeline.total &&
          $store.state.idea.storyMode.setUp.startupCosts.timeline.byYear"
        :title="$t('charts.totalStartupCosts')"
        :total="$store.state.idea.storyMode.setUp.startupCosts.timeline.total"
        :by-year="$store.state.idea.storyMode.setUp.startupCosts.timeline.byYear"
      />
      <!-- /Right Side -->

    </el-row>
  </div>
  <!-- /Startup Cost Timeline -->

</template>

<script>
import ChartHeader from '@/views/Home/StoryMode/Components/BusinessPlan/ChartHeader'
import MixinCalculations from '@/mixins/calculations'
import MixinCharts from '@/mixins/charts'
import MixinCurrencies from '@/mixins/currencies'
import StartupCostsOverTimeChart from '@/views/Home/StoryMode/SetUp/StartupCosts/Charts/StartupCostsOverTimeChart'
import TimelinePeriodSwitcherBusinessPlan from '@/components/Charts/Timeline/TimelinePeriodSwitcherBusinessPlan.vue'
import TimelineRightSide from '@/components/Charts/Timeline/TimelineRightSide'

export default {
  name: 'StartupCostsTimeline',

  components: {
    TimelinePeriodSwitcherBusinessPlan,
    ChartHeader,
    StartupCostsOverTimeChart,
    TimelineRightSide
  },

  mixins: [
    MixinCalculations,
    MixinCharts,
    MixinCurrencies
  ],

  props: {
    chartAdjustment: {
      type: Object,
      default: () => ({
        chartHeight: 370,
        headingTitleFontSize: '22px',
        xLabelTitleFontSize: '12px',
        yLabelTitleFontSize: '14px',
        yaxisLabelsMinWidth: {
          timeLine: 70
        }
      })
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      forceUpdate: true,
      period: 'monthly'
    }
  },

  methods: {
    switchChartPeriod (period) {
      this.$set(this, 'period', period)
    }
  }
}
</script>
