<template>
  <vue-apex-charts
    ref="chart"
    type="bar"
    :height="chartAdjustment.chartHeight"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
import EventBus from '@/event-bus'
import MixinCalculations from '@/mixins/calculations'
import MixinCharts from '@/mixins/charts'
import MixinCurrencies from '@/mixins/currencies'
import VueApexCharts from 'vue-apexcharts'
import responsiveChartOptions from '@/data/responsiveChartOptions'

export default {
  name: 'CashFlowFromFinancingActivitiesChart',

  components: {
    VueApexCharts
  },

  mixins: [
    MixinCalculations,
    MixinCharts,
    MixinCurrencies
  ],

  props: {
    period: {
      type: String,
      default: 'monthly'
    },
    chartAdjustment: {
      type: Object,
      default: () => ({
        chartHeight: 370,
        headingTitleFontSize: '22px',
        xLabelTitleFontSize: '12px',
        yLabelTitleFontSize: '14px',
        yaxisLabelsMinWidth: {
          breakDown: 70
        }
      })
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    chartAnimations: {
      type: Boolean,
      default: false
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          background: '#fff',
          stacked: true,
          fontFamily: 'outfit-light,arial',
          animations: {
            enabled: this.chartAnimations,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            }
          }
        },
        title: {
          text: !this.hideTitle ? 'Cash flow from financing activities222' : '',
          margin: 20,
          offsetX: 20,
          offsetY: 30,
          style: {
            fontSize: this.chartAdjustment.headingTitleFontSize,
            color: '#263238',
            fontFamily: 'outfit-light,arial'
          }
        },
        grid: {
          borderColor: 'transparent'
        },
        annotations: {
          position: 'back',
          yaxis: [
            {
              y: 0,
              borderColor: '#afafb1'
            }
          ]
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            colors: {
              ranges: [{
                from: 1,
                to: 10000,
                color: '#4cda63'
              }, {
                from: -1000,
                to: -1,
                color: '#e53935'
              }]
            }
          }
        },
        colors: [
          '#4cda63', '#e53935'
        ],
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['transparent']
        },
        xaxis: {
          categories: this.getCategories(),
          labels: {
            show: true,
            style: {
              color: '#afafb1',
              fontSize: this.chartAdjustment.xLabelTitleFontSize,
              fontFamily: 'outfit-light,arial',
              cssClass: 'apexcharts-xaxis-label'
            }
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false,
            color: '#fafafa',
            height: 1,
            width: '100%',
            offsetX: 0,
            offsetY: 0
          },
          crosshairs: {
            show: true,
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 1,
              opacity: 0.4
            }
          }
        },
        yaxis: {
          tickAmount: 5,
          min: undefined,
          max: undefined,
          labels: {
            formatter: this.formatChartYLabels,
            show: true,
            align: 'right',
            minWidth: this.chartAdjustment.yaxisLabelsMinWidth.timeLine,
            maxWidth: 120,
            style: {
              color: '#afafb1',
              fontSize: this.chartAdjustment.xLabelTitleFontSize,
              fontFamily: 'outfit-light,arial',
              cssClass: 'apexcharts-yaxis-label',
              opacity: 0.7
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0
          },
          crosshairs: {
            show: false,
            width: 0,
            position: 'back',
            opacity: 0,
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 1,
              opacity: 0
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          marker: {
            show: false
          },
          fillSeriesColor: false,
          y: {
            title: {
              formatter (seriesName) {
                return seriesName
              }
            },
            formatter: this.formatTooltip
          },
          x: {
            show: false
          },
          theme: 'dark'
        },
        legend: {
          show: false
        },
        responsive: responsiveChartOptions
      }
    }
  },

  computed: {
    series () {
      const series = [
        {
          name: '',
          data: []
        },
        {
          name: '',
          data: []
        }
      ]

      if (
        this.$store.state.idea.storyMode.setUp.financing.timeline &&
        this.$store.state.idea.storyMode.setUp.financing.timeline.data
      ) {
        const data = this.createSeriesDataFromCalculation(
          this.$store.state.idea.storyMode.setUp.financing.timeline.data
        )
        for (let i = 0; i < data.length; i++) {
          if (data[i] > 0) {
            series[0].data.push(data[i])
            series[1].data.push(0)
          }

          if (data[i] < 0) {
            series[0].data.push(0)
            series[1].data.push(data[i])
          }

          if (data[i] === 0) {
            series[0].data.push(0)
            series[1].data.push(0)
          }
        }
      }

      return series
    }
  },

  watch: {
    period () {
      this.$set(this.chartOptions.xaxis, 'categories', this.getCategories())
      this.$refs.chart.refresh()
    }
  },

  mounted () {
    EventBus.$on('idea-settings-update', () => {
      if (this.$refs.chart !== undefined) {
        this.$refs.chart.refresh()
      }
    })
  }
}
</script>
