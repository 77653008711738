<template>
  <div
    class="editable-description-wrapper"
    :class="classesWrapper"
    @keydown.esc="onCancel"
  >
    <!-- If Disabled -->
    <template v-if="disabled">
      <div v-html="content" />
    </template>
    <!-- If Not Disabled -->
    <template v-else>
      <div
        v-if="!editMode"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
      >
        <!-- Empty text -->
        <span
          v-if="!contentLength"
          class="empty-text-placeholder"
          @click="editMode = true"
          v-html="emptyText"
        />
        <!-- Empty text -->
        <!-- Content -->
        <div
          class="editable-description-content"
          @click="editMode = true"
          v-html="content"
        />
        <!-- Content -->
      </div>
      <!-- Edit mode -->
      <template v-if="editMode">
        <ib-loader-overlay v-if="loading" />
        <!-- Quill editor -->
        <template v-if="editorType === editorEnum.QUILL">
          <quill-editor
            ref="quillEditor"
            v-model="content"
            :options="editorOptions"
            @ready="onReady"
            @change="onChange"
          />
        </template>
        <!-- Quill editor -->

        <!-- Jodit editor -->
        <template v-if="editorType === editorEnum.JODIT">
          <jodit-editor
            ref="joditEditor"
            v-model="content"
            :config="joditEditorConfig"
            :buttons="joditEditorButtons"
          />
        </template>
        <!-- Jodit editor -->

        <div class="actions-wrapper">
          <el-button
            icon="el-icon-check"
            type="plus-round-xs"
            :disabled="isUnsupportedPluginOn"
            @click="onSave"
          />
          <el-button
            icon="el-icon-close"
            type="plus-round-xs"
            @click="onCancel"
          />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import IbLoaderOverlay from '@/components/_v2/IbLoaderOverlay.vue'
import MixinUnsupportedPlugins from '@/mixins/unsupportedPlugins'
import editorEnum from '@/constants/editorEnum'

import { Jodit } from 'jodit'
import { JoditEditor } from 'jodit-vue'
import { mapActions } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import { validateImage } from '@/helpers/imageHelper'

export default {
  name: 'EditableDescription',

  components: {
    IbLoaderOverlay,
    JoditEditor,
    quillEditor
  },

  mixins: [MixinUnsupportedPlugins],

  props: {
    value: {
      type: String,
      required: true,
      default: ''
    },
    emptyText: {
      type: String,
      default: function () { return this.$t('pages.businessPlan.sections.addDescriptionOptional') }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    limitCharacters: {
      type: Number,
      default: 0
    },
    defaultEditMode: {
      type: Boolean,
      default: false
    },
    editorType: {
      type: String,
      default: editorEnum.JODIT
    }
  },

  data () {
    return {
      loading: false,
      editMode: false,
      hover: false,
      startValue: this.value,
      firstLoad: true,
      editorOptions: {
        modules: {
          toolbar: {
            container: [
              [{ header: 1 }, { header: 2 }],
              ['bold', 'italic', 'underline'],
              [{ align: [] }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['blockquote'],
              ['link'],
              ['clean']
            ]
          }
        },
        placeholder: this.emptyText
      },
      editorEnum
    }
  },

  computed: {
    content: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },

    editor () {
      return this.editMode && this.$refs.quillEditor.quill
    },

    contentLength () {
      return this.content.length
    },

    classesWrapper () {
      return {
        hover: this.hover && !this.editMode
      }
    },

    joditEditorConfig () {
      return {
        minHeight: 352,
        toolbarButtonSize: 'middle',
        inline: false,
        showPlaceholder: true,
        placeholder: this.emptyText,
        showCharsCounter: false,
        showWordsCounter: false,
        showXPathInStatusbar: false,
        limitChars: 10000,
        askBeforePasteHTML: false,
        defaultActionOnPaste: 'insert_clear_html',
        controls: {
          paragraph: {
            list: Jodit.atom({
              h2: 'Heading 1',
              h3: 'Heading 2',
              p: 'Paragraph',
              blockquote: 'Quote'
            })
          }
        },
        disablePlugins: ['addNewLine']
      }
    },

    joditEditorButtons () {
      return [
        'bold',
        'italic',
        'underline',
        // 'strikethrough',
        // 'eraser',
        'paragraph',
        // 'superscript',
        // 'subscript',
        'hr',
        'ul',
        'ol',
        'brush',
        'link',
        'table',
        {
          name: 'Insert image',
          tooltip: 'Insert picture',
          iconURL: require('@/assets/img/icons/jodit-image.svg'),
          exec: this.joditImageButtonHandler
        }
      ]
    }
  },

  mounted () {
    if (this.defaultEditMode) {
      this.editMode = true
    }
  },

  updated () {
    if (this.firstLoad) {
      this.startValue = this.value
      this.firstLoad = false
    }
  },

  methods: {
    ...mapActions('idea', ['uploadImage']),

    onReady (event) {
      event.focus()
    },

    onSave () {
      this.editMode = false
      this.hover = false
      this.updateStartValue()
      this.$emit('save', this.content)
    },

    onCancel () {
      this.editMode = false
      this.hover = false
      this.contentToStartValue()
      this.closeUnsupportedPluginNotification()
      this.$emit('cancel')
    },

    onChange () {
      if (this.isUnsupportedPluginOn) {
        this.showUnsupportedPluginNotification(this.content)

        return
      }

      if (this.limitCharacters) {
        this.resetToLimit()
      }
      if (this.editor.getLength() === 1) {
        this.resetContent()
      }
    },

    contentToStartValue () {
      this.content = this.startValue
    },

    updateStartValue () {
      this.startValue = this.content
    },

    resetContent () {
      this.content = ''
    },

    resetToLimit () {
      if (this.editor.getLength() >= this.limitCharacters) {
        this.editor.deleteText(this.limitCharacters, this.editor.getLength())
      }
    },

    joditImageButtonHandler () {
      const jodit = this.$refs.joditEditor.editor

      if (jodit.getEditorValue().replace(/(<([^>]+)>)/ig, '').length > 10000) {
        alert('You have reached the maximum content length, you can not upload any more images!')
      } else {
        // Add a file input element to the document, then click it (open file).
        const input = document.createElement('input')
        input.hidden = true
        input.setAttribute('type', 'file')
        input.click()
        document.body.appendChild(input)
        // Once file is selected.
        input.onchange = () => {
          const file = input.files[0]
          const images = jodit.currentPlace.editor.getElementsByTagName('img').length
          if (!validateImage(file, images)) return
          // Create form.
          const formData = new FormData()
          formData.append('image', file)

          this.loading = true
          this.uploadImage({ formData, type: 'business-plan' })
            .then(response => {
              jodit.selection.insertImage(response.url)
              this.loading = false
            })
            .catch(_ => {
              this.loading = false
            })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.editable-description-wrapper {
  position: relative;
  margin: 12px 0;
  border-radius: 3px;
  cursor: text;
  padding: 5px;

  &.hover {
    background: #e8e9ed;
  }

  ::v-deep {
    .editable-description-content {
      table {
        border: none;
        border-collapse: collapse;
        empty-cells: show;
        margin-bottom: 1em;
        max-width: 100%;
      }

      ul, ol {
        li {
          &:before {
            color: $color-navy;
          }
        }
        color: $color-navy;
      }

      blockquote {
        color: $color-navy;
      }

      table tr td {
        border: 1px solid #dadada;
        min-width: 2em;
        padding: 0.4em;
        user-select: text;
        vertical-align: middle;
        color: $color-navy;
      }
    }
  }
}

.actions-wrapper {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.empty-text-placeholder {
  color: #b3b3b3;
  display: block;
  cursor: text;
}
</style>
