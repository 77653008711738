<template>
  <div
    v-if="$store.state.idea.storyMode.common.completedSteps.revenueStreamsCompleted === true && isRevenueStreamsVisible"
    class="journal-content dynamic-height"
    :class="{'dynamic-height': !isOption(revenueStreamData, 'breakdownSection') && !isOption(revenueStreamData, 'timelineSection') && !isOption(revenueStreamData, 'tableSection')}"
  >
    <div class="content-menus">
      <div v-if="moreVisible === true" class="view-type">

        <template v-if="revenueStreams.length !== 0">
          <a
            class="orange"
            :class="{'orange-off': !isOption(revenueStreamData, 'breakdownSection')}"
            @click="enableBreakdown"
          >
            {{ $t('pages.businessPlan.sections.breakdown') }}
          </a>
          <a
            class="orange"
            :class="{'orange-off': !isOption(revenueStreamData, 'timelineSection')}"
            @click="enableTimeline"
          >
            {{ $t('pages.businessPlan.sections.timeline') }}
          </a>
          <a
            class="orange"
            :class="{'orange-off': !isOption(revenueStreamData, 'tableSection')}"
            @click="enableTable"
          >
            {{ $t('pages.businessPlan.sections.table') }}
          </a>
        </template>
        <p v-if="revenueStreams.length === 0">
          {{ $t('pages.businessPlan.sections.youDontHaveAnyDataOnThisPage') }}
        </p>
      </div>
      <div v-if="canEdit" class="mini-actions">
        <toggle-page-items-button
          :visible="moreVisible"
          @click="toggleMore"
        />
        <toggle-view-button
          :disabled="!cardExist"
          :horizontal="!isOption(revenueStreamData, 'gridListView')"
          @click="toggleRotate"
        />
        <toggle-notes-button
          :disabled="!moreDetails"
          :visible="isOption(revenueStreamData, 'moreDetailsDisplay')"
          @click="enableMoreDetails"
        />
        <edit-section-link route-name="business-guide-projections-revenue-streams" />
      </div>
    </div>

    <div class="sub chapter">
      <header>
        <!-- Title section -->
        <editable-title
          v-model="revenueStreamData.title"
          tag="h1"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Title section -->
        <hr>
        <!-- Description section -->
        <editable-description
          v-model="revenueStreamData.subTitle"
          :empty-text="$t('addMoreDetails.addNoteText')"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Description section -->
      </header>

      <template v-if="revenueStreams.length !== 0">
        <!-- Revenue Streams Breakdown -->
        <div v-if="isOption(revenueStreamData, 'breakdownSection')" class="switcher-inner-wrapper breakdown-chart">
          <revenue-streams-breakdown
            :update-chart="updateCharts"
            :chart-adjustment="chartAdjustment"
          />
        </div>
        <!-- /Revenue Streams Breakdown -->
        <div v-if="isOption(revenueStreamData, 'timelineSection')" class="timeline-chart">
          <revenue-streams-timeline
            :update-chart="updateCharts"
            :chart-adjustment="chartAdjustment"
          />
        </div>

        <div v-if="isOption(revenueStreamData, 'tableSection')" class="table-chart">
          <revenue-streams-table
            :update-chart="updateCharts"
            :table-adjustment="tableAdjustment"
          />
        </div>
      </template>

      <!-- More Details section -->
      <template v-if="moreDetails && isOption(revenueStreamData, 'moreDetailsDisplay')">
        <div class="details">
          <editable-title
            v-model="revenueStreamData.moreDetailsTitle"
            class="bold"
            tag="p"
            :disabled="!canEdit"
            @save="onSave"
          />
          <p v-html="moreDetails" />
        </div>
      </template>
      <!-- More Details section -->
    </div>
  </div>
</template>

<script>
import EditSectionLink from '@/views/Home/StoryMode/Components/BusinessPlan/EditSectionLink'
import EditableDescription from '@/components/_v2/EditableDescription'
import EditableTitle from '@/components/_v2/EditableTitle'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import RevenueStreamsBreakdown from '@/views/Home/StoryMode/Forecast/RevenueStreams/Charts/RevenueStreamsBreakdown'
import RevenueStreamsTable from '@/views/Home/StoryMode/Forecast/RevenueStreams/Tables/RevenueStreamsTable'
import RevenueStreamsTimeline from '@/views/Home/StoryMode/Forecast/RevenueStreams/Charts/RevenueStreamsTimeline'
import ToggleNotesButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleNotesButton'
import TogglePageItemsButton from '@/views/Home/StoryMode/Components/BusinessPlan/TogglePageItemsButton'
import ToggleViewButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleViewButton'
import journalMethods from '@/mixins/journalMethods'

export default {
  name: 'RevenueStreams',

  components: {
    ToggleNotesButton,
    TogglePageItemsButton,
    ToggleViewButton,
    EditSectionLink,
    RevenueStreamsBreakdown,
    RevenueStreamsTimeline,
    RevenueStreamsTable,
    EditableTitle,
    EditableDescription
  },

  mixins: [
    journalMethods,
    MixinIdeaRoles
  ],

  props: {
    allPagesTextData: {
      type: Object,
      default: () => ({})
    },
    shareMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      cardExist: false,
      revenueStreamData: {},

      moreVisible: false,
      updateCharts: true,

      moreDetails: this.$store.state.idea.storyMode.common.moreDetails.revenueStreamsMoreDetails,
      revenueStreams: this.$store.state.idea.storyMode.forecast.revenueStreams.revenueStreams,

      chartAdjustment: {
        chartHeight: 260,
        headingTitleFontSize: '18px',
        xLabelTitleFontSize: '10px',
        yLabelTitleFontSize: '12px',
        yaxisLabelsMinWidth: {
          breakDown: 0,
          timeLine: 0
        }
      },
      tableAdjustment: {
        journalPage: true,
        period: 'yearly',
        tableFirstColumnWidth: 250,
        columnWidth: 118
      },
      switchSelector: 'payroll'
    }
  },

  computed: {
    isRevenueStreamsVisible () {
      const index = this.$store.state.idea.journal.journalSwitchBars.indexOf(
        this.$store.state.idea.journal.journalSwitchBars.find(journalElement => journalElement.sectionName === 'RevenueStreams')
      )

      return !this.$store.state.idea.journal.journalSwitchBars[index].options.includes('switch')
    },

    isTitleEnabled () {
      return this.revenueStreamData.title
    },

    isMoreDetailsTitleEnabled () {
      return this.revenueStreamData.moreDetailsTitle
    }
  },

  mounted () {
    this.revenueStreamData = Object.assign({}, this.allPagesTextData)
  },

  methods: {
    toggleRotate () {
      if (this.cardExist) {
        this.toggleOption(this.revenueStreamData, 'gridListView')
        this.revenueStreamData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    enableMoreDetails () {
      if (this.moreDetails) {
        this.toggleOption(this.revenueStreamData, 'moreDetailsDisplay')
        this.revenueStreamData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    onSave () {
      this.$emit('save', this.revenueStreamData)
    },

    enableTimeline () {
      this.toggleOption(this.revenueStreamData, 'timelineSection')
      this.revenueStreamData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    },

    enableBreakdown () {
      this.toggleOption(this.revenueStreamData, 'breakdownSection')
      this.revenueStreamData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    },

    enableTable () {
      this.toggleOption(this.revenueStreamData, 'tableSection')
      this.revenueStreamData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    }
  }
}
</script>
