<template>
  <div id="dialog-add-new-section">
    <el-dialog
      :visible="dialogAddNewSectionVisible"
      :close-on-click-modal="true"
      :fullscreen="true"
      @open="dialogOpen"
      @close="dialogClose"
      @closed="dialogClosed"
    >
      <div class="dialog-body">

        <h2>Add Journal Section:</h2>

        <div class="form-wrapper">
          <span class="label">Section Name:</span>
          <el-form
            ref="ruleForm"
            :model="form"
          >
            <el-form-item>
              <el-input
                v-model="form.title"
                placeholder="Note Title"
              />
            </el-form-item>
          </el-form>

          <!-- Quill Wrapper -->
          <div class="quill-wrapper">
            <span class="label">Section Content:</span>
            <quill-editor
              v-model="form.subTitle"
              :options="editorOptions"
            />
          </div>
          <!-- /Quill Wrapper -->
        </div>

        <!-- Dialog Save Button -->
        <el-button
          class="small"
          @click="saveJournalSection"
        >
          {{ form.id === 0 ? 'Add' : 'Save' }}
        </el-button>
        <!-- /Dialog Save Button -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'DialogAddNewSection',

  components: {
    quillEditor
  },

  props: {
    dialogAddNewSectionVisible: {
      type: Boolean,
      default: false
    },
    pageData: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      editorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['blockquote'],
            ['link', 'image']
          ]
        },
        placeholder: this.$t('placeholderDescribe')
      },

      form: {
        id: 0,
        title: '',
        subTitle: '',
        ideaId: this.$store.state.idea.id
      },

      loading: false
    }
  },

  methods: {
    saveJournalSection () {
      if (this.form.id === 0) {
        this.$emit('on-save', this.form)
      } else {
        this.$emit('on-edit', this.form)
      }
    },

    toggleFocus (input) {
      input.focus = !input.focus
      if (input.value.length) input.focus = true
    },

    dialogOpen () {
      this.resetForm()
      if (this.pageData !== null) {
        this.form.id = this.pageData.id
        this.form.title = this.pageData.title
        this.form.subTitle = this.pageData.subTitle
      }
    },

    resetForm () {
      this.form = {
        id: 0,
        ideaId: this.$store.state.idea.id,
        title: '',
        subTitle: ''
      }
    },

    dialogClose () {
      this.$emit('on-close-dialog')
    },

    dialogClosed () {
      this.resetForm()
    }

  }
}
</script>

<style scoped>

</style>
