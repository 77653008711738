<template>

  <!-- Direct Cost Timeline -->
  <el-row :gutter="20">

    <!-- Left Side -->
    <el-col :span="18" class="position-relative">
      <chart-header>
        {{ $t('charts.directCostsOverTime') }}
        <template #right>
          <!-- Timeline Period Switcher -->
          <timeline-period-switcher-business-plan
            size="xs"
            @switch-to-monthly="switchToMonthly"
            @switch-to-quarterly="switchToQuarterly"
            @switch-to-yearly="switchToYearly"
          />
          <!-- /Timeline Period Switcher -->
        </template>
      </chart-header>

      <!-- Chart -->
      <direct-costs-over-time-chart
        :period="period"
        :chart-adjustment="chartAdjustment"
        chart-animations
        hide-title
      />
      <!-- /Chart -->

    </el-col>
    <!-- /Left Side -->

    <!-- Right Side -->
    <timeline-right-side v-if="$store.state.idea.storyMode.forecast.directCosts.timeline.total
                           && $store.state.idea.storyMode.forecast.directCosts.timeline.byYear"
                         title="Total Direct Costs"
                         :total="$store.state.idea.storyMode.forecast.directCosts.timeline.total"
                         :by-year="$store.state.idea.storyMode.forecast.directCosts.timeline.byYear"
    />
    <!-- /Right Side -->

  </el-row>
  <!-- /Direct Cost Timeline -->

</template>

<script>
import ChartHeader from '@/views/Home/StoryMode/Components/BusinessPlan/ChartHeader'
import DirectCostsOverTimeChart from '@/views/Home/StoryMode/Forecast/DirectCosts/Charts/DirectCostsOverTimeChart'
import MixinCalculations from '@/mixins/calculations'
import MixinCharts from '@/mixins/charts'
import MixinCurrencies from '@/mixins/currencies'
import TimelinePeriodSwitcherBusinessPlan from '@/components/Charts/Timeline/TimelinePeriodSwitcherBusinessPlan.vue'
import TimelineRightSide from '@/components/Charts/Timeline/TimelineRightSide'

export default {
  name: 'DirectCostsTimeline',

  components: {
    TimelinePeriodSwitcherBusinessPlan,
    ChartHeader,
    DirectCostsOverTimeChart,
    TimelineRightSide
  },

  mixins: [
    MixinCalculations,
    MixinCharts,
    MixinCurrencies
  ],

  props: {
    chartAdjustment: {
      type: Object,
      default: () => ({
        chartHeight: 370,
        headingTitleFontSize: '22px',
        xLabelTitleFontSize: '12px',
        yLabelTitleFontSize: '12px',
        yaxisLabelsMinWidth: {
          timeLine: 70
        }
      })
    },
    switchSelector: {
      type: String,
      default: 'direct-costs'
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      period: 'monthly'
    }
  }
}

</script>
