<template>
  <div
    v-if="$store.state.idea.storyMode.common.completedSteps.ideaCompleted && isIdeaVisible"
    class="journal-content dynamic-height"
  >
    <div class="content-menus">
      <div
        v-if="moreVisible"
        class="view-type"
      >
        <a
          v-if="why"
          class="orange"
          :class="{'orange-off': !isOption(ideaData, 'whyCard')}"
          @click="enableWhy"
        >
          {{ $t('pages.businessPlan.sections.idea.whyProblem') }}
        </a>
        <a
          v-if="what"
          class="orange"
          :class="{'orange-off': !isOption(ideaData, 'whatCard')}"
          @click="enableWhat"
        >
          {{ $t('pages.businessPlan.sections.idea.whatSolution') }}
        </a>

        <p v-if="!why && !what">
          {{ $t('pages.businessPlan.sections.youDontHaveAnyDataOnThisPage') }}
        </p>
      </div>
      <div v-show="canEdit" class="mini-actions">
        <toggle-page-items-button
          :visible="moreVisible"
          @click="toggleMore"
        />
        <toggle-view-button
          :disabled="!cardExist"
          :horizontal="!isOption(ideaData, 'gridListView')"
          @click="toggleRotate"
        />
        <toggle-notes-button
          :disabled="!moreDetails"
          :visible="isOption(ideaData, 'moreDetailsDisplay')"
          @click="enableMoreDetails"
        />
        <edit-section-link route-name="business-guide-concept-idea" />
      </div>
    </div>

    <div class="sub chapter">
      <header>
        <!-- Title section -->
        <editable-title
          v-model="ideaData.title"
          tag="h1"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Title section -->
        <hr>
        <!-- Description section -->
        <editable-description
          v-model="ideaData.subTitle"
          :empty-text="$t('addMoreDetails.addNoteText')"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Description section -->
      </header>

      <template v-if="why">
        <div
          v-if="isOption(ideaData, 'whyCard')"
          class="journal-idea-card why"
        >
          <h1>{{ $t('pages.businessPlan.sections.idea.whyProblem') }}</h1>
          <hr>
          <span class="editor-wrapper" v-html="why" />
        </div>
      </template>

      <template v-if="what">
        <div
          v-if="isOption(ideaData, 'whatCard')"
          class="journal-idea-card what"
        >
          <h1>{{ $t('pages.businessPlan.sections.idea.whatSolution') }}</h1>
          <hr>
          <span class="editor-wrapper" v-html="what" />
        </div>
      </template>

      <!-- More Details section -->
      <template v-if="moreDetails && isOption(ideaData, 'moreDetailsDisplay')">
        <div class="details">
          <editable-title
            v-model="ideaData.moreDetailsTitle"
            class="bold"
            tag="p"
            :disabled="!canEdit"
            @save="onSave"
          />
          <p v-html="moreDetails" />
        </div>
      </template>
      <!-- More Details section -->
    </div>
  </div>
</template>

<script>
import EditSectionLink from '@/views/Home/StoryMode/Components/BusinessPlan/EditSectionLink'
import EditableDescription from '@/components/_v2/EditableDescription'
import EditableTitle from '@/components/_v2/EditableTitle'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import ToggleNotesButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleNotesButton'
import TogglePageItemsButton from '@/views/Home/StoryMode/Components/BusinessPlan/TogglePageItemsButton'
import ToggleViewButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleViewButton'
import journalMethods from '@/mixins/journalMethods'

export default {
  name: 'Idea',

  components: {
    TogglePageItemsButton,
    ToggleViewButton,
    ToggleNotesButton,
    EditSectionLink,
    EditableTitle,
    EditableDescription
  },

  mixins: [
    journalMethods,
    MixinIdeaRoles
  ],

  props: {
    allPagesTextData: {
      type: Object,
      default: () => ({})
    },
    shareMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      cardExist: false,
      moreVisible: false,
      ideaData: {},

      coverageCard: true,
      growthCard: true,

      whyNotLoaded: true,
      whatNotLoaded: true,

      why: null,
      what: null,
      moreDetails: this.$store.state.idea.storyMode.common.moreDetails.ideaMoreDetails
    }
  },

  computed: {
    isIdeaVisible () {
      const index = this.$store.state.idea.journal.journalSwitchBars.indexOf(
        this.$store.state.idea.journal.journalSwitchBars.find(journalElement => journalElement.sectionName === 'Idea')
      )

      return !this.$store.state.idea.journal.journalSwitchBars[index].options.includes('switch')
    },

    isTitleEnabled () {
      return this.ideaData.title
    },

    isMoreDetailsTitleEnabled () {
      return this.ideaData.moreDetailsTitle
    }
  },

  created () {
    this.getWhy()
    this.getWhat()
  },

  mounted () {
    this.ideaData = Object.assign({}, this.allPagesTextData)
  },

  methods: {
    toggleRotate () {
      if (this.cardExist) {
        this.toggleOption(this.ideaData, 'gridListView')
        this.ideaData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    enableMoreDetails () {
      if (this.moreDetails) {
        this.toggleOption(this.ideaData, 'moreDetailsDisplay')
        this.ideaData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    onSave () {
      this.$emit('save', this.ideaData)
    },

    getWhy () {
      if (this.$store.state.idea.storyMode.concept.idea.why) {
        this.why = this.$store.state.idea.storyMode.concept.idea.why

        return
      }

      if (this.$store.getters['user/isLoggedIn']) {
        this.$http.get('story-mode/concept/idea/why', { params: { ideaId: this.$store.state.idea.id } })
          .then(response => {
            this.why = response.data.payload.ideaWhy
            this.whyNotLoaded = false
          }).catch(() => {
            this.whyNotLoaded = false
          })
      }
    },

    getWhat () {
      if (this.$store.state.idea.storyMode.concept.idea.what) {
        this.what = this.$store.state.idea.storyMode.concept.idea.what

        return
      }

      if (this.$store.getters['user/isLoggedIn']) {
        this.$http.get('story-mode/concept/idea/what', { params: { ideaId: this.$store.state.idea.id } })
          .then(response => {
            this.what = response.data.payload.ideaWhat
            this.whatNotLoaded = false
          }).catch(() => {
            this.whatNotLoaded = false
          })
      }
    },

    enableWhy () {
      this.toggleOption(this.ideaData, 'whyCard')
      this.ideaData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    },

    enableWhat () {
      this.toggleOption(this.ideaData, 'whatCard')
      this.ideaData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    }
  }
}
</script>
