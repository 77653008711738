var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"switch-wrapper"},[_c('form-switcher',{attrs:{"size":_vm.size,"options":[{
      title: _vm.$t('monthly'),
      value: 'monthly',
      callback: _vm.switchToMonthly
    },{
      title: _vm.$t('quarterly'),
      value: 'quarterly',
      callback: _vm.switchToQuarterly
    },{
      title: _vm.$t('yearly'),
      value: 'yearly',
      callback: _vm.switchToYearly
    }]},on:{"change":function($event){return _vm.$emit('change', _vm.period)}},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }