<template>
  <div
    v-if="$store.state.idea.storyMode.common.completedSteps.customersCompleted === true && isCustomersVisible"
    class="journal-content dynamic-height"
    :class="{'dynamic-height': customers.length > 5 }"
  >
    <div class="content-menus">
      <div v-if="moreVisible === true" class="view-type">
        <a
          v-if="customers.length !== 0"
          class="orange"
          :class="{'orange-off': !isOption(customerData, 'customerItemDisplay')}"
          @click="enableCustomers"
        >
          {{ $t('pages.businessPlan.sections.customers.customersTable') }}
        </a>
        <p v-if="customers.length === 0">
          {{ $t('pages.businessPlan.sections.youDontHaveAnyDataOnThisPage') }}
        </p>
      </div>
      <div v-if="canEdit" class="mini-actions">
        <toggle-page-items-button
          :visible="moreVisible"
          @click="toggleMore"
        />
        <toggle-view-button
          :disabled="!cardExist"
          :horizontal="!isOption(customerData, 'gridListView')"
          @click="toggleRotate"
        />
        <toggle-notes-button
          :disabled="!moreDetails"
          :visible="isOption(customerData, 'moreDetailsDisplay')"
          @click="enableMoreDetails"
        />
        <edit-section-link route-name="business-guide-research-customers" />
      </div>
    </div>
    <div class="sub chapter">
      <header>
        <!-- Title section -->
        <editable-title
          v-model="customerData.title"
          tag="h1"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Title section -->
        <hr>
        <!-- Description section -->
        <editable-description
          v-model="customerData.subTitle"
          :empty-text="$t('addMoreDetails.addNoteText')"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Description section -->
      </header>

      <template v-if="customers.length !== 0">
        <div
          v-if="isOption(customerData, 'customerItemDisplay')"
          class="table-wrapper"
          :class="{'list-view': !isOption(customerData, 'gridListView')}"
        >
          <div
            v-for="customer in customers"
            :key="customer.id"
            class="journal-card customer-card"
          >
            <div
              class="card-top"
              :class="{'default': !customer.image }"
              :style="cardImageBySize(customer.image, 'medium')"
            />
            <div class="card-bottom">
              <div class="top-segment">
                <h3>{{ customer.name }}</h3>
                <div class="dashed-divider" />
                <p class="pre-formatted desc">
                  {{ customer.description }}
                </p>
                <div class="dashed-divider" />
                <p class="bold">
                  {{ $t('pages.businessPlan.sections.customers.painPoints') }}
                </p>

                <ul class="pain-points">
                  <li v-for="(point, index) in customer.painPoints" :key="index">
                    <p>{{ point.value }}</p>
                  </li>
                </ul>
              </div>

              <div class="bottom-segment">
                <div class="dashed-divider" />
                <ul class="grading-list">
                  <li>
                    <p>{{ $t('pages.businessPlan.sections.customers.marketSize') }}</p>
                    <p class="bold">
                      {{ customer.segmentSize }}<span class="regular">/5</span>
                    </p>
                  </li>
                  <li>
                    <p>{{ $t('pages.businessPlan.sections.customers.payForValue') }}</p>
                    <p class="bold">
                      {{ customer.willingnessToPay }}<span class="regular">/5</span>
                    </p>
                  </li>
                  <li>
                    <p>{{ $t('pages.businessPlan.sections.customers.accessibility') }}</p>
                    <p class="bold">
                      {{ customer.accessibility }}<span class="regular">/5</span>
                    </p>
                  </li>
                </ul>
                <div class="priority"
                     :class="[getCustomerLevel(customer) === $t('medium') ? 'medium': '' || getCustomerLevel(customer) === $t('low') ? 'low': '']"
                >
                  <p>{{ $t('pages.businessPlan.sections.customers.priority') }}</p>
                  <p class="title">
                    {{ getCustomerLevel(customer) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- More Details section -->
      <template v-if="moreDetails && isOption(customerData, 'moreDetailsDisplay')">
        <div class="details">
          <editable-title
            v-model="customerData.moreDetailsTitle"
            class="bold"
            tag="p"
            :disabled="!canEdit"
            @save="onSave"
          />
          <p v-html="moreDetails" />
        </div>
      </template>
      <!-- More Details section -->
    </div>
  </div>
</template>

<script>
import EditSectionLink from '@/views/Home/StoryMode/Components/BusinessPlan/EditSectionLink'
import EditableDescription from '@/components/_v2/EditableDescription'
import EditableTitle from '@/components/_v2/EditableTitle'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import ToggleNotesButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleNotesButton'
import TogglePageItemsButton from '@/views/Home/StoryMode/Components/BusinessPlan/TogglePageItemsButton'
import ToggleViewButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleViewButton'
import journalMethods from '@/mixins/journalMethods'

export default {
  name: 'Customers',

  components: {
    EditSectionLink,
    EditableDescription,
    EditableTitle,
    ToggleNotesButton,
    TogglePageItemsButton,
    ToggleViewButton
  },

  mixins: [
    journalMethods,
    MixinIdeaRoles
  ],

  props: {
    allPagesTextData: {
      type: Object,
      default: () => ({})
    },
    shareMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      cardExist: true,
      customerData: {},
      moreVisible: false,
      customers: this.$store.state.idea.storyMode.research.customers.customers,
      moreDetails: this.$store.state.idea.storyMode.common.moreDetails.customersMoreDetails
    }
  },

  computed: {
    isCustomersVisible () {
      const index = this.$store.state.idea.journal.journalSwitchBars.indexOf(
        this.$store.state.idea.journal.journalSwitchBars.find(journalElement => journalElement.sectionName === 'Customers')
      )

      return !this.$store.state.idea.journal.journalSwitchBars[index].options.includes('switch')
    },

    isTitleEnabled () {
      return this.customerData.title
    },

    isMoreDetailsTitleEnabled () {
      return this.customerData.moreDetailsTitle
    }
  },

  mounted () {
    this.customerData = Object.assign({}, this.allPagesTextData)
  },

  methods: {
    toggleRotate () {
      if (this.cardExist) {
        this.toggleOption(this.customerData, 'gridListView')
        this.customerData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    enableMoreDetails () {
      if (this.moreDetails) {
        this.toggleOption(this.customerData, 'moreDetailsDisplay')
        this.customerData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    onSave () {
      this.$emit('save', this.customerData)
    },

    enableCustomers () {
      this.toggleOption(this.customerData, 'customerItemDisplay')
      this.customerData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    },

    getCustomerLevel (customer) {
      // noinspection JSUnresolvedVariable
      const attributesLevel = customer.segmentSize + customer.willingnessToPay + customer.accessibility

      if (attributesLevel <= 5) {
        return this.$t('low')
      } else if (attributesLevel <= 10) {
        return this.$t('medium')
      }

      return this.$t('high')
    }
  }
}
</script>
