<template>
  <div
    v-if="$store.state.idea.storyMode.common.completedSteps.promotionCompleted === true && isPromotionVisible"
    class="journal-content dynamic-height"
    :class="{'dynamic-height': promotionalActivities.length > 10 }"
  >
    <div class="content-menus">
      <div v-if="moreVisible === true" class="view-type">
        <a
          v-if="promotionalActivities.length !== 0"
          class="orange"
          :class="{'orange-off': !isOption(promotionData, 'promotionItemsDisplay')}"
          @click="enablePromotion"
        >
          {{ $t('pages.businessPlan.sections.promotion.promotionItems') }}
        </a>
        <p v-if="promotionalActivities.length === 0">
          {{ $t('pages.businessPlan.sections.youDontHaveAnyDataOnThisPage') }}
        </p>
      </div>
      <div v-if="canEdit" class="mini-actions">
        <a class="more" :class="{'horizontal': moreVisible === true}" @click="toggleMore">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 341.333 341.333" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M170.667 85.333c23.573 0 42.667-19.093 42.667-42.667C213.333 19.093 194.24 0 170.667 0S128 19.093 128 42.667c0 23.573 19.093 42.666 42.667 42.666zM170.667 128C147.093 128 128 147.093 128 170.667s19.093 42.667 42.667 42.667 42.667-19.093 42.667-42.667S194.24 128 170.667 128zM170.667 256C147.093 256 128 275.093 128 298.667c0 23.573 19.093 42.667 42.667 42.667s42.667-19.093 42.667-42.667C213.333 275.093 194.24 256 170.667 256z"/></svg>
        </a>
        <a
          class="list-grid"
          :class="{'horizontal': !isOption(promotionData, 'gridListView'), 'disabled': !cardExist}"
          @click="toggleRotate"
        >
          <svg width="20" height="13" viewBox="0 0 20 13" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 0h18a1 1 0 011 1v1a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1zm0 5h18a1 1 0 011 1v1a1 1 0 01-1 1H1a1 1 0 01-1-1V6a1 1 0 011-1zm0 5h18a1 1 0 011 1v1a1 1 0 01-1 1H1a1 1 0 01-1-1v-1a1 1 0 011-1z" fill="none" fill-rule="evenodd" opacity=".205"/></svg>
        </a>
        <toggle-notes-button
          :disabled="!moreDetails"
          :visible="isOption(promotionData, 'moreDetailsDisplay')"
          @click="enableMoreDetails"
        />
        <edit-section-link route-name="business-guide-concept-promotion" />
      </div>
    </div>
    <div class="sub chapter">
      <header>
        <!-- Title section -->
        <editable-title
          v-model="promotionData.title"
          tag="h1"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Title section -->
        <hr>
        <!-- Description section -->
        <editable-description
          v-model="promotionData.subTitle"
          :empty-text="$t('addMoreDetails.addNoteText')"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Description section -->
      </header>

      <template v-if="promotionalActivities.length !== 0">
        <div
          v-if="isOption(promotionData, 'promotionItemsDisplay')"
          class="table-wrapper"
          :class="{'list-view': !isOption(promotionData, 'gridListView')}"
        >

          <div
            v-for="promoItem in promotionalActivities"
            :key="promoItem.id"
            class="journal-card promotion-card"
          >
            <div
              class="card-top" :class="[{'default': !promoItem.image}, promoItem.type]"
              :style="cardImageBySize(promoItem.image, 'medium')"
            />
            <div class="card-bottom">
              <h3>{{ promoItem.name }}</h3>
              <div class="dashed-divider" />
              <p class="pre-formatted desc">
                {{ promoItem.description }}
              </p>
            </div>
          </div>
        </div>
      </template>

      <!-- More Details section -->
      <template v-if="moreDetails && isOption(promotionData, 'moreDetailsDisplay')">
        <div class="details">
          <editable-title
            v-model="promotionData.moreDetailsTitle"
            class="bold"
            tag="p"
            :disabled="!canEdit"
            @save="onSave"
          />
          <p v-html="moreDetails" />
        </div>
      </template>
      <!-- More Details section -->
    </div>
  </div>
</template>

<script>
import EditSectionLink from '@/views/Home/StoryMode/Components/BusinessPlan/EditSectionLink'
import EditableDescription from '@/components/_v2/EditableDescription'
import EditableTitle from '@/components/_v2/EditableTitle'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import ToggleNotesButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleNotesButton'
import journalMethods from '@/mixins/journalMethods'

export default {
  name: 'Promotion',

  components: {
    EditSectionLink,
    EditableDescription,
    EditableTitle,
    ToggleNotesButton
  },

  mixins: [
    journalMethods,
    MixinIdeaRoles
  ],

  props: {
    allPagesTextData: {
      type: Object,
      default: () => ({})
    },
    shareMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      cardExist: true,
      promotionData: {},
      moreVisible: false,
      moreDetails: this.$store.state.idea.storyMode.common.moreDetails.promotionMoreDetails,
      promotionalActivities: this.$store.state.idea.storyMode.concept.promotion.promotionalActivities
    }
  },

  computed: {
    isPromotionVisible () {
      const index = this.$store.state.idea.journal.journalSwitchBars.indexOf(
        this.$store.state.idea.journal.journalSwitchBars.find(journalElement => journalElement.sectionName === 'Promotion')
      )

      return !this.$store.state.idea.journal.journalSwitchBars[index].options.includes('switch')
    },

    isTitleEnabled () {
      return this.promotionData.title
    },

    isMoreDetailsTitleEnabled () {
      return this.promotionData.moreDetailsTitle
    }
  },

  mounted () {
    this.promotionData = Object.assign({}, this.allPagesTextData)
  },

  methods: {
    toggleRotate () {
      if (this.cardExist) {
        this.toggleOption(this.promotionData, 'gridListView')
        this.promotionData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    enableMoreDetails () {
      if (this.moreDetails) {
        this.toggleOption(this.promotionData, 'moreDetailsDisplay')
        this.promotionData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    onSave () {
      this.$emit('save', this.promotionData)
    },

    enablePromotion () {
      this.toggleOption(this.promotionData, 'promotionItemsDisplay')
      this.promotionData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    }
  }
}
</script>
