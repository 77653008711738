<template>

  <!-- Marketing Budget Timeline -->
  <div class="switcher-inner-wrapper">
    <el-row :gutter="20">

      <!-- Left Side -->
      <el-col :span="18" class="position-relative">
        <chart-header>
          {{ $t('charts.marketingBudgetOverTime') }}
          <template #right>
            <!-- Timeline Period Switcher -->
            <timeline-period-switcher-business-plan size="xs" @change="switchChartPeriod($event)" />
            <!-- /Timeline Period Switcher -->
          </template>
        </chart-header>

        <!-- Chart -->
        <marketing-budget-over-time-chart
          :period="period"
          :chart-adjustment="chartAdjustment"
          chart-animations
          hide-title
        />
        <!-- /Chart -->
      </el-col>
      <!-- /Left Side -->

      <!-- Right Side -->
      <timeline-right-side v-if="$store.state.idea.storyMode.forecast.marketingBudget.timeline.total
                             && $store.state.idea.storyMode.forecast.marketingBudget.timeline.byYear"
                           :title="$t('totalMarketingBudget')"
                           :total="$store.state.idea.storyMode.forecast.marketingBudget.timeline.total"
                           :by-year="$store.state.idea.storyMode.forecast.marketingBudget.timeline.byYear"
      />
      <!-- /Right Side -->

    </el-row>
  </div>
  <!--/ Startup Cost Timeline -->
</template>

<script>
import ChartHeader from '@/views/Home/StoryMode/Components/BusinessPlan/ChartHeader'
import MarketingBudgetOverTimeChart from '@/views/Home/StoryMode/Forecast/MarketingBudget/Charts/MarketingBudgetOverTimeChart'
import MixinCalculations from '@/mixins/calculations'
import MixinCharts from '@/mixins/charts'
import MixinCurrencies from '@/mixins/currencies'
import TimelinePeriodSwitcherBusinessPlan from '@/components/Charts/Timeline/TimelinePeriodSwitcherBusinessPlan.vue'
import TimelineRightSide from '@/components/Charts/Timeline/TimelineRightSide'

export default {
  name: 'MarketingBudgetTimeline',

  components: {
    TimelinePeriodSwitcherBusinessPlan,
    ChartHeader,
    MarketingBudgetOverTimeChart,
    TimelineRightSide
  },

  mixins: [
    MixinCalculations,
    MixinCharts,
    MixinCurrencies
  ],

  props: {
    updateChart: {
      type: Boolean
    },
    chartAdjustment: {
      type: Object,
      default: () => ({
        chartHeight: 370,
        headingTitleFontSize: '22px',
        xLabelTitleFontSize: '12px',
        yLabelTitleFontSize: '14px',
        yaxisLabelsMinWidth: {
          timeLine: 70
        }
      })
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      period: 'monthly'
    }
  },

  methods: {
    switchChartPeriod (period) {
      this.$set(this, 'period', period)
    }
  }
}
</script>
