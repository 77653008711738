<template>

  <!-- Revenue Streams Breakdown -->
  <el-row :gutter="20">

    <!-- Left Side -->
    <el-col :span="18">
      <chart-header>
        {{ $t('charts.revenueDistribution') }}
      </chart-header>
      <revenue-distribution-chart
        :chart-adjustment="chartAdjustment"
        chart-animations
        hide-title
      />
    </el-col>
    <!-- /Left Side -->

    <!-- Right Side -->
    <el-col :span="6">
      <div class="breakdown">

        <!-- Total Revenue Streams -->
        <ib-tooltip v-if="Math.abs(totalMonthlyAverage) >= 1.0e+7" :content="'Total Monthly (Average): ' + chartTooltipDisplay(totalMonthlyAverage)" placement="right" :open-delay="0">
          <p class="total-costs">
            <!--{{ currencySymbol }}{{ totalMonthlyAverage }}-->
            {{ chartValueFormatter(totalMonthlyAverage) }}
          </p>
        </ib-tooltip>

        <p v-else class="total-costs">
          <!--{{ currencySymbol }}{{ totalMonthlyAverage }}-->
          {{ chartValueFormatter(totalMonthlyAverage) }}
        </p>
        <!-- /Total Revenue Streams -->

        <!-- Total Revenue Streams Title -->
        <p class="text-small">
          {{ $t('totalMonthlyAverage') }}
        </p>
        <!-- /Total Revenue Streams Title -->

        <!-- Details -->
        <div class="details">

          <!-- Top 3 Revenue Streams -->
          <p class="text-small">
            {{ $t('topThreeAverage') }}
          </p>
          <!-- /Top 3 Revenue Streams -->

          <!-- Revenue Streams -->
          <div v-for="(topRevenueStream, index) in topRevenueStreams" :key="topRevenueStream.id" class="flex">
            <div class="number">
              {{ index + 1 }}
            </div>
            <div class="name-and-amount">
              <ib-tooltip v-if="Math.abs(topRevenueStream[1]) >= 1.0e+7" :content="topRevenueStream[0] + ': ' + chartTooltipDisplay(topRevenueStream[1])" placement="right" :open-delay="0">
                <p class="bold">
                  {{ chartValueFormatter(topRevenueStream[1]) }}
                </p>
              </ib-tooltip>

              <p v-else class="bold">
                {{ chartValueFormatter(topRevenueStream[1]) }}
              </p>

              <p class="text-small">
                {{ topRevenueStream[0] }}
              </p>
            </div>
          </div>
          <!-- /Revenue Streams -->

        </div>
        <!-- /Details -->

      </div>
    </el-col>
    <!-- /Right Side -->

  </el-row>
  <!-- /Revenue Streams Breakdown -->

</template>

<script>
import ChartHeader from '@/views/Home/StoryMode/Components/BusinessPlan/ChartHeader'
import MixinCurrencies from '@/mixins/currencies'
import RevenueDistributionChart from '@/views/Home/StoryMode/Forecast/RevenueStreams/Charts/RevenueDistributionChart'

export default {
  name: 'RevenueStreamsBreakdown',

  components: {
    ChartHeader,
    RevenueDistributionChart
  },

  mixins: [
    MixinCurrencies
  ],

  props: {
    chartAdjustment: {
      type: Object,
      default: () => ({
        chartHeight: 370,
        headingTitleFontSize: '22px',
        xLabelTitleFontSize: '12px',
        yLabelTitleFontSize: '14px',
        yaxisLabelsMinWidth: {
          breakDown: 250
        }
      })
    },

    sharedIdea: {
      type: Object,
      default: null
    }
  },

  computed: {
    totalMonthlyAverage () {
      let result = 0

      if (this.$store.state.idea.storyMode.forecast.revenueStreams.breakdown.total) {
        result = this.$store.state.idea.storyMode.forecast.revenueStreams.breakdown.total
      }

      return result
    },

    topRevenueStreams () {
      let sorted = []

      if (this.$store.state.idea.storyMode.forecast.revenueStreams.breakdown.sorted) {
        sorted = this.$store.state.idea.storyMode.forecast.revenueStreams.breakdown.sorted
      }

      return sorted
    }
  }
}
</script>
