<template>
  <div>
    <img
      v-if="isEmpty"
      src="@/assets/img/placeholders/pie-chart-placeholder.svg"
      alt="pie chart placeholder"
    >
    <vue-apex-charts
      v-else
      ref="chart"
      type="donut"
      :height="height"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import EventBus from '@/event-bus'
import VueApexCharts from 'vue-apexcharts'
import chartOptions from '@/views/Home/StoryMode/SetUp/Ownership/Components/Charts/OwnersChart/chartOptions'

import { mapGetters } from 'vuex'

export default {
  name: 'OwnersChart',

  components: {
    VueApexCharts
  },

  props: {
    height: {
      type: Number,
      default: 370
    }
  },

  data () {
    return {
      chartOptions
    }
  },

  computed: {
    ...mapGetters('idea/ownership', ['getOwners']),

    series () {
      if (!this.getOwners.length) return []

      this.setLabels(this.getOwners.map(owner => owner.name))
      this.setColors(this.getOwners.map(owner => owner.color))

      return this.getOwners.map(owner => owner.percentage)
    },

    labels () {
      return this.getOwners.map(owner => owner.name)
    },

    isEmpty () {
      return !this.series.length
    }
  },

  watch: {
    getOwners (value) {
      if (!value.length) {
        this.refresh()
      }
    }
  },

  mounted () {
    EventBus.$on('owners-changed', () => {
      this.refresh()
    })
  },

  destroyed () {
    EventBus.$off('owners-changed')
  },

  methods: {
    setLabels (labels) {
      this.chartOptions.labels = labels
    },

    setColors (colors) {
      this.chartOptions.colors = colors
    },

    refresh () {
      this.$refs.chart.refresh()
    }
  }
}
</script>
