<template>
  <ib-tooltip popper-class="plan-popper">
    <!-- Tooltip Content -->
    <div
      slot="content"
      class="tooltip-content"
    >
      <p>{{ $t('pages.businessPlan.editSectionInBusinessGuide') }}</p>
    </div>
    <!-- /Tooltip Content -->

    <a @click="$router.push({name: routeName})">
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#3e474f" opacity=".5" fill-rule="evenodd" d="M1.5 12.78l8.34-8.575 2.14 2.197-8.35 8.56H1.5v-2.183zM12.125 1.853l2.15 2.203-1.23 1.26L10.9 3.119l1.225-1.265zM.75 16.498h3.19c.2 0 .39-.082.53-.226L15.865 4.6a.777.777 0 000-1.086L12.655.225a.74.74 0 00-.53-.225.74.74 0 00-.53.225L.22 11.92a.796.796 0 00-.22.543v3.268c0 .425.335.768.75.768z"/></svg>
    </a>
  </ib-tooltip>
</template>

<script>
export default {
  name: 'EditSectionLink',

  props: {
    routeName: {
      type: String,
      required: true
    }
  }
}
</script>
