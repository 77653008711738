<template>
  <div
    class="d-flex align-items-center guide-btn-wrapper"
    :class="{'active': active}"
    @click="$emit('click')"
  >
    <i
      class="btn-icon mr-2 text-silver"
      :class="icon"
      :style="{fontSize: iconSize}"
    />
    <span class="btn-text">
      {{ title }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'IbToggleButton',

  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    iconSize: {
      type: String,
      default: '23px'
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.guide-btn-wrapper {
  background-color: $color-white;
  padding: 10px 20px;
  border: 1px solid $color-blue;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;

  .btn-text {
    font-size: 17px;
    color: #393e47;
    transition: all 0.25s;
    user-select: none;
  }

  .btn-icon {
    transition: all 0.25s;
  }

  &.active {
    transition: all 0.25s;
    box-shadow: 2px 3px 8px 0 rgba(2, 145, 255, 0.34), 0 0 2px 0 rgba(2, 145, 255, 0.15);

    .btn-text,
    .btn-icon {
      color: $color-blue;
      transition: all 0.25s;
    }
  }
}
</style>
