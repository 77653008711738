<template>
  <div class="owners-list-table pb-3 pl-3">
    <table>
      <tr v-if="header">
        <th class="color" />
        <th class="text-navy">
          {{ $t('pages.businessGuide.ownership.name') }}
        </th>
        <th class="text-navy text-right">
          {{ $t('pages.businessGuide.ownership.share') }}
        </th>
      </tr>
      <tr v-for="owner in owners" :key="owner.id">
        <td class="color-wrapper">
          <owners-list-table-color
            :class="{'m-auto': header}"
            :color="owner.color"
          />
        </td>
        <td class="text-navy">
          {{ owner.name }}
        </td>
        <td class="percentage text-navy text-right">
          {{ formatNumber(owner.percentage) }}%
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import OwnersListTableColor from '@/views/Home/StoryMode/SetUp/Ownership/Components/OwnersList/OwnersListTableColor'
import { formatNumber } from '@/helpers/utilityHelpers'

export default {
  name: 'OwnersListTable',

  components: {
    OwnersListTableColor
  },

  props: {
    owners: {
      type: Array,
      required: true
    },
    header: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    formatNumber
  }
}
</script>

<style scoped lang="scss">
.owners-list-table {
  max-height: 210px;
  overflow: hidden auto;
  padding-right: 24px;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  tr {
    th {
      padding: 0 0 30px;

      &.color {
        width: 60px;
      }
    }

    td {
      padding: 10px 0 20px;

      &.percentage {
        font-size: 20px;
      }
    }

    &:last-child {
      th {
        padding: 0;
      }

      td {
        padding: 10px 0 0;
      }
    }
  }

  td, th {
    text-align: left;
  }
}

</style>
