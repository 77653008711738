import { render, staticRenderFns } from "./ProfitAndLossTable.vue?vue&type=template&id=6d0e64a8&scoped=true"
import script from "./ProfitAndLossTable.vue?vue&type=script&lang=js"
export * from "./ProfitAndLossTable.vue?vue&type=script&lang=js"
import style0 from "./ProfitAndLossTable.vue?vue&type=style&index=0&id=6d0e64a8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d0e64a8",
  null
  
)

export default component.exports