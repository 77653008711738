<template>

  <!-- Revenue Streams Timeline -->
  <div class="switcher-inner-wrapper">
    <el-row :gutter="20">

      <!-- Left Side -->
      <el-col :span="18" class="position-relative">
        <chart-header>
          {{ $t('charts.revenueOverTime') }}
          <template #right>
            <!-- Timeline Period Switcher -->
            <timeline-period-switcher-business-plan size="xs" @change="switchChartPeriod($event)" />
            <!-- /Timeline Period Switcher -->
          </template>
        </chart-header>

        <!-- Chart -->
        <revenue-over-time-chart
          :period="period"
          :chart-adjustment="chartAdjustment"
          chart-animations
          hide-title
        />
        <!-- /Chart -->

      </el-col>
      <!-- /Left Side -->

      <!-- Right Side -->
      <timeline-right-side v-if="$store.state.idea.storyMode.forecast.revenueStreams.timeline.total
                             && $store.state.idea.storyMode.forecast.revenueStreams.timeline.byYear"
                           :text="$t('charts.totalRevenueStreams')"
                           :total="$store.state.idea.storyMode.forecast.revenueStreams.timeline.total"
                           :by-year="$store.state.idea.storyMode.forecast.revenueStreams.timeline.byYear"
      />
      <!-- /Right Side -->

    </el-row>
  </div>
  <!-- /Revenue Streams Timeline -->

</template>

<script>
import ChartHeader from '@/views/Home/StoryMode/Components/BusinessPlan/ChartHeader'
import MixinCalculations from '@/mixins/calculations'
import MixinCharts from '@/mixins/charts'
import MixinCurrencies from '@/mixins/currencies'
import RevenueOverTimeChart from '@/views/Home/StoryMode/Forecast/RevenueStreams/Charts/RevenueOverTimeChart'
import TimelinePeriodSwitcherBusinessPlan from '@/components/Charts/Timeline/TimelinePeriodSwitcherBusinessPlan.vue'
import TimelineRightSide from '@/components/Charts/Timeline/TimelineRightSide'

export default {
  name: 'RevenueStreamsTimeline',

  components: {
    TimelinePeriodSwitcherBusinessPlan,
    ChartHeader,
    RevenueOverTimeChart,
    TimelineRightSide
  },

  mixins: [
    MixinCalculations,
    MixinCharts,
    MixinCurrencies
  ],

  props: {
    chartAdjustment: {
      type: Object,
      default: () => ({
        chartHeight: 370,
        headingTitleFontSize: '22px',
        xLabelTitleFontSize: '12px',
        yLabelTitleFontSize: '14px',
        yaxisLabelsMinWidth: {
          timeLine: 70
        }
      })
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      period: 'monthly'
    }
  },

  methods: {
    switchChartPeriod (period) {
      this.$set(this, 'period', period)
    },

    formatTooltip (value) {
      if (value) {
        // TODO - Check if we should show decimal on timeline
        const decimalPlaces = Number.isInteger(value) || value.toString().substr(value.toString().indexOf('.') + 1).startsWith('00') ? 0 : 0

        return this.currencySymbol + '' + this.getFormattedAmount(value, decimalPlaces)
      }
    }
  }
}
</script>
