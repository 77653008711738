const OwnershipTypesEnum = {
  SALE_PROPRIETORSHIP: 1,
  PARTNERSHIP: 2,
  LIMITED_LIABILITY_COMPANY: 3,
  CORPORATION: 4,
  OTHER: 5,
  NONPROFIT_ORGANIZATION: 6
}

const OwnershipTypes = [
  {
    id: OwnershipTypesEnum.SALE_PROPRIETORSHIP,
    name: 'pages.businessGuide.ownership.types.soleProprietorship',
    description: 'pages.businessGuide.ownership.types.soleProprietorshipDescription'
  },
  {
    id: OwnershipTypesEnum.PARTNERSHIP,
    name: 'pages.businessGuide.ownership.types.partnership',
    description: 'pages.businessGuide.ownership.types.partnershipDescription'
  },
  {
    id: OwnershipTypesEnum.CORPORATION,
    name: 'pages.businessGuide.ownership.types.corporation',
    description: 'pages.businessGuide.ownership.types.corporationDescription'
  },
  {
    id: OwnershipTypesEnum.LIMITED_LIABILITY_COMPANY,
    name: 'pages.businessGuide.ownership.types.limitedLiabilityCompany',
    description: 'pages.businessGuide.ownership.types.limitedLiabilityCompanyDescription'
  },
  {
    id: OwnershipTypesEnum.NONPROFIT_ORGANIZATION,
    name: 'pages.businessGuide.ownership.types.nonprofitOrganization',
    description: 'pages.businessGuide.ownership.types.nonprofitOrganizationDescription'
  },
  {
    id: OwnershipTypesEnum.OTHER,
    name: 'pages.businessGuide.ownership.types.other',
    description: 'pages.businessGuide.ownership.types.otherDescription'
  }
]

export { OwnershipTypesEnum as default, OwnershipTypes }
